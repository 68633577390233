import React from "react";
import styles from "./NotificationList.module.css";
import ReportItem from "../../report/ReportItem";
import { useReport } from "../../../contexts/ReportContext";
import Loader from "../../common/Loader";
import EmptyList from "../../report/EmptyList";

function NotificationList() {
  const { reports, loading } = useReport();
  console.log(reports);
  const unseenReports = reports?.filter((report) => !report.report.seen) || [];

  if (loading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {unseenReports.length > 0 ? (
          unseenReports.map((report) => {
            return <ReportItem key={report.id} data={report} />;
          })
        ) : (
          <div>
            <EmptyList />
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationList;
