import React from 'react';
import styles from './PhoneNumberInput.module.css';

const PhoneNumberInput = ({ placeholder, value, onChange, name }) => {
  return (
    <div className={styles.phoneNumberContainer}>
      <input
        className={styles.phoneNumberInput}
        type="text"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default PhoneNumberInput;
