import React from "react";
import { Bar } from "react-chartjs-2";
import styles from "./TotalMoodTracker.module.css";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const TotalMoodTracker = ({ MoodData }) => {
  const labels = MoodData?.map((entry) =>
    new Date(entry.date).toLocaleDateString("en-US", { weekday: "short" })
  );

  const happyData = MoodData?.map((entry) => entry.happy);
  const neutralData = MoodData?.map((entry) => entry.neutral);
  const sadData = MoodData?.map((entry) => entry.sad);
  const overwhelmedData = MoodData?.map((entry) => entry.overwhelmed);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Happy",
        data: happyData,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderWidth: 0,
        barThickness: 10,
        borderRadius: 10,
      },
      {
        label: "Neutral",
        data: neutralData,
        backgroundColor: "rgba(255, 206, 86, 0.5)",
        borderWidth: 0,
        barThickness: 10,
        borderRadius: 10,
      },
      {
        label: "Sad",
        data: sadData,
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderWidth: 0,
        barThickness: 10,
        borderRadius: 10,
      },
      {
        label: "Overwhelmed",
        data: overwhelmedData,
        backgroundColor: "rgba(255, 159, 64, 0.5)",
        borderWidth: 0,
        barThickness: 10,
        borderRadius: 10,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        ticks: { font: { size: 14 } },
        categoryPercentage: 0.5,
        barPercentage: 0.5,
      },
      y: {
        stacked: true,
        grid: { display: false },
        ticks: { display: false },
        suggestedMax: 1, // Adjust this based on your data range
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 20,
          boxHeight: 10,
          font: { size: 18 },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        top: 10,
        bottom: 10,
      },
    },
  };

  return (
    <div className={styles.container}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default TotalMoodTracker;
