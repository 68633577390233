import React from 'react'
import style from './CompleteInfo.module.css'
import CompleteInfoForm from '../../components/auth/CompleteInfoForm'

function CompleteInfo() {
  return (
    <div className={style.container}>
        <CompleteInfoForm />
    </div>
  )
}

export default CompleteInfo

