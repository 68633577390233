import React from "react";
import styles from "./LogOutButton.module.css";
import LogoutIcon from "./LogoutIcon";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
function LogOutButton() {
  const { logout } = useAuth();
  const Navigate = useNavigate();
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        logout();
        Navigate("/");
      }}
      className={styles.logout}
    >
      <LogoutIcon />
      Log Out
    </div>
  );
}

export default LogOutButton;
