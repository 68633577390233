import React, { createContext, useContext, useState, useEffect } from 'react';
import { manageEmployeeService } from '../services/manageEmployeeService';

const EmployeeContext = createContext();

export const EmployeeProvider = ({ children }) => {
  const [employee, setEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);

  const fetchEmployee = async (employeeId) => {
    setLoading(true);
    try {
      const data = await manageEmployeeService.fetchEmployee(employeeId);
      setEmployee(data);
    } catch (error) {
      console.error('Failed to fetch employee:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllEmployees = async () => {
    setLoading(true);
    try {
      const data = await manageEmployeeService.fetchAllEmployees();
      if (data.message === "success") {
         setEmployees(data.data);
      } else {
          console.error('Failed to fetch employees:', data);
        }
     
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    } finally {
      setLoading(false);
    }
  };

  const addEmployee = async (employeeData) => {
    setLoading(true);
    try {
      const data = await manageEmployeeService.addEmployee(employeeData);
      setEmployees((prevEmployees) => [...prevEmployees, data]);
    } catch (error) {
      console.error('Failed to add employee:', error);
    } finally {
      setLoading(false);
    }
  };

  const editEmployee = async (employeeId, updatedData) => {
    setLoading(true);
    try {
      const data = await manageEmployeeService.editEmployee(employeeId, updatedData);
      setEmployees((prevEmployees) =>
        prevEmployees.map((emp) => (emp.id === employeeId ? data : emp))
      );
    } catch (error) {
      console.error('Failed to edit employee:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteEmployee = async (employeeId) => {
    setLoading(true);
    try {
      await manageEmployeeService.deleteEmployee(employeeId);
      setEmployees((prevEmployees) => prevEmployees.filter((emp) => emp.id !== employeeId));
    } catch (error) {
      console.error('Failed to delete employee:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllEmployees();
  }, []);

  return (
    <EmployeeContext.Provider value={{
      employee,
      employees,
      loading,
      fetchEmployee,
      fetchAllEmployees,
      addEmployee,
      editEmployee,
      deleteEmployee,
      showAddEmployeeModal,
      setShowAddEmployeeModal,
    }}>
      {children}
    </EmployeeContext.Provider>
  );
};

export const useEmployee = () => useContext(EmployeeContext);
