import React from "react";
import styles from "./EmployeeItem.module.css";

function EmployeeItem({
  name,
  email,
  phone,
  department,
  team,
  jobTitle,
  employeeId,
  section,
}) {
  return (
    <tr className={styles.wrapper}>
      <td className={styles.name}>{name}</td>
      <td className={styles.email}>{email}</td>
      <td className={styles.phone}>{phone}</td>
      <td className={styles.department}>{department}</td>
      <td className={styles.section}>{section}</td>
      <td className={styles.team}>{team}</td>
      <td className={styles.jobTitle}>{jobTitle}</td>
      <td className={styles.employeeId}>{employeeId}</td>
    </tr>
  );
}

export default EmployeeItem;
