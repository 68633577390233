import React from "react";
import styles from "./LeadScoreWidget.module.css";
import ScoreLeaders from "./ScoreLeaders";
import { useNavigate } from "react-router-dom";
import { useScore } from "../../../contexts/ScoreContext";
function LeadScoreWidget() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/lead-scoring");
  };
  const { loading, scores } = useScore();
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Lead Scoring </h2>
        <span className={styles.more} onClick={handleClick}>
          more
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M6 12.5L10 8.5L6 4.5"
              stroke="#7FC7D9"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className={styles.content}>
        <ScoreLeaders loading={loading} leaders={scores} />
      </div>
    </div>
  );
}

export default LeadScoreWidget;
