import React, { useState } from 'react';
import styles from './RegisterForm.module.css';
import EmailInput from '../../common/EmailInput';
import PasswordInput from '../../common/PasswordInput';
import SubmitButton from '../../common/buttons/SubmitButton';
import url from '../../../assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import Separator from '../../common/Separator';
import googleIcon from '../../../assets/images/google-icon.png';
import facebookIcon from '../../../assets/images/facebook-icon.png';
import IconButton from '../../common/buttons/IconButton';
import { validateEmail, validatePassword } from '../../../utils/validation';
import { useAuth } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';

function RegisterForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { register } = useAuth();
  const Navigate = useNavigate();
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!validateEmail(value)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (!validatePassword(value)) {
      setPasswordError('Password must be at least 8 characters long');
    } else {
      setPasswordError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email) && validatePassword(password)) {
      try {
        console.log(email)
        const res = await register( email, password );
        if (res){
          Navigate('/auth/complete-info');
        }
        
      } catch (error) {
        toast.error(`Registration failed. ${error}`);
      }
    } else {
      toast.error('Please fix the errors in the form');
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.logo}>
          <img src={url} alt="logo" />
        </div>
        <h1 className={styles.title}>Create Account</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles['field-wrapper']}>
            <EmailInput value={email} onChange={handleEmailChange} />
            <div className={`${styles['error-wrapper']} ${emailError ? styles.show : ''}`}>
              {emailError && <div className={styles.error}>{emailError}</div>}
            </div>
           
            <PasswordInput value={password} onChange={handlePasswordChange} />
            <div className={`${styles['error-wrapper']} ${passwordError ? styles.show : ''}`}>
              {passwordError && <div className={styles.error}>{passwordError}</div>}
            </div>
          </div>
          <SubmitButton title={'Create Account'} action={handleSubmit} />
          <div className={styles.alternative}>
            <span>Already have an account?</span>
            <Link to="/auth/login">Log In</Link>
          </div>
          <Separator>Or Register With</Separator>
          <div className={styles.row}>
            <IconButton icon={googleIcon} action={() => alert('Google')}>
              Google
            </IconButton>
            <IconButton icon={facebookIcon} action={() => alert('Facebook')}>
              Facebook
            </IconButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;
