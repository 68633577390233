import React, { useState } from "react";
import styles from "./Team.module.css";
import { structureService } from "../../../services/structureService";
import { useStructure } from "../../../contexts/StructureContext";
import { toast } from "react-toastify";

const Team = ({ team }) => {
  const { fetchTeams } = useStructure();
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(team.title);

  const handleDelete = (id) => {
    structureService
      .deleteTeam(id)
      .then(() => {
        fetchTeams();
      })
      .catch((error) => {
        console.error("Failed to delete team:", error);
      });
  };

  const handleEdit = (id) => {
    setIsEditing(true);
  };

  const handleSave = async (id) => {
    try {
      const res = await structureService.editTeam(id, newTitle)
      toast.info(res.message)
    } catch(error){
      toast.error(error)
    }
  };

  const handleChange = (e) => {
    setNewTitle(e.target.value);
  };

  return (
    <div className={styles.team}>
      <span className={styles[`team-name`]}>
        <span className={styles.name}>
          <div>Team</div>
          <div id="">
            {isEditing ? (
              <input
                type="text"
                value={newTitle}
                onChange={handleChange}
                autoFocus
              />
            ) : (
              <div>{team.title}</div>
            )}
          </div>
        </span>
      </span>
      <span
        onClick={(e) => {
          e.stopPropagation(); // Prevent collapse toggle
          isEditing ? handleSave(team.id) : handleEdit(team.id);
        }}
        className={styles[`edit-icon`]}
      >
        {isEditing ? "✔️" : "✏️"}
      </span>
      <span
        onClick={(e) => {
          e.stopPropagation(); // Prevent collapse toggle
          handleDelete(team.id);
        }}
        className={styles[`delete-icon`]}
      >
        🗑️
      </span>
    </div>
  );
};

export default Team;
