import styles from './SelectType.module.css'
import { useStructure } from '../../../contexts/StructureContext';
function SelectType() {

const {setShowSelectedType,setShowSelectTypeModal, setAddType} = useStructure();

function dispatchType(type){
    setAddType(type)
    setShowSelectedType(true)
    setShowSelectTypeModal(false)
}
  return (
    <div className={styles.selectType}>
        <div className={styles.selectTypeOptions}>
            <div onClick={()=>{dispatchType('Department')}} className={styles.selectTypeOption}>Department</div>
            <div onClick={()=>{dispatchType('Section')}} className={styles.selectTypeOption}>Section</div>
            <div onClick={()=>{dispatchType('Team')}} className={styles.selectTypeOption}>Team</div>
        </div>
    </div>
  )
}

export default SelectType