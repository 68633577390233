import { post, put, del, get } from "../utils/api";

export const structureService = {
  getDepartments: async () => {
    try {
      const response = await get("management/departments/");
      return response.data;
    } catch (error) {
      console.error("Error getting departments:", error);
      throw error;
    }
  },
  addDepartment: async (department) => {
    const data = {
      departments: department,
    };
    try {
      const response = await post("management/departments/", data);
      return response;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  editDepartment: async (id, department) => {
    const data = {
      title: department,
    };
    try {
      const response = await put(
        `management/departments/?department_id=${id}`,
        data
      );
      return response;
    } catch (error) {
      console.error("Error editing department:", error);
      throw error;
    }
  },
  deleteDepartment: async (id) => {
    try {
      const response = await del(`management/departments/?department_id=${id}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting department:", error);
      throw error;
    }
  },
  getSections: async () => {
    const response = await get(`management/sections/`);
    return response.data;
  },
  addSection: async (departmentId, sections) => {
    const data = {
      department_id: departmentId,
      sections: sections,
    };
    try {
      const response = await post("management/sections/", data);
      return response;
    } catch (error) {
      console.error("Error adding section:", error);
      return error;
    }
  },
  editSection: async (id, section) => {
    const data = {
      title: section,
    };
    try {
      const response = await put(`management/sections/?section_id=${id}`, data);
      return response;
    } catch (error) {
      console.error("Error editing section:", error);
      throw error;
    }
  },
  deleteSection: async (id) => {
    try {
      const response = await del(`management/sections/?section_id=${id}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting section:", error);
      throw error;
    }
  },
  getTeams: async () => {
    const response = await get(`management/teams/`);
    return response.data;
  },
  addTeam: async (sectionId, teams) => {
    const data = {
      section_id: sectionId,
      teams: teams,
    };
    try {
      const response = await post("management/teams/", data);
      return response;
    } catch (error) {
      console.error("Error adding team:", error);
      throw error;
    }
  },
  editTeam: async (id, team) => {
    const data = {
      title: team,
    };
    try {
      const response = await put(`management/teams/?team_id=${id}`, data);
      return response;
    } catch (error) {
      console.error("Error editing team:", error);
      throw error;
    }
  },
  deleteTeam: async (id) => {
    try {
      const response = await del(`management/teams/?team_id=${id}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting team:", error);
      throw error;
    }
  },
};
