import React from 'react'
import EditProfileForm from '../../components/auth/EditProfileForm'

function index() {
  return (
    <div>
        <EditProfileForm />
    </div>
  )
}

export default index