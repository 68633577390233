import React from 'react';
import styles from './ModalBackdrop.module.css';
import PropTypes from 'prop-types';

function ModalBackdrop({ children, onClick }) {
  return <div className={styles.modalBackdrop} onClick={onClick}>{children}</div>;
}

ModalBackdrop.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};


export default ModalBackdrop;
