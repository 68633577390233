import React, { useState } from "react";
import styles from "./ChangePaassword.module.css";
import { authService } from "../../services/authService";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

function ChangePaassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { resetToken } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Password length validation
    if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New password and confirmation do not match.");
      return;
    }

    setLoading(true);
    const data = {
      new_password: confirmPassword,
    };

    try {
      if (resetToken === null) {
        toast.error("Something went wrong, please try again.");
        navigate("/auth/forgot-password");
      }

      const res = await authService.changePassword(data, resetToken);

      if (res.message.includes("successfully")) {
        setSuccess("Password changed successfully.");
        setError("");
        toast.success("Password changed successfully.");
        navigate("/auth/login");
      } else {
        setError(res.message || "An error occurred.");
        setSuccess("");
        toast.error(res.message || "An error occurred.");
      }
    } catch (err) {
      setError("Old password is incorrect.");
      setSuccess("");
      toast.error("Old password is incorrect.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <h1>Change Password</h1>
        <form className={styles.form} onSubmit={handleSubmit}>
          {error && <div className={styles.error}>{error}</div>}
          {success && <div className={styles.success}>{success}</div>}

          <div className={styles.inputBox}>
            <input
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              value={newPassword}
              onChange={handleChange}
              required
            />
            <div className={styles.labelBox}>
              <label>New Password</label>
              <span
                className={styles.eyeIcon}
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div className={styles.inputBox}>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleChange}
              required
            />
            <div className={styles.labelBox}>
              <label>Confirm Password</label>
              <span
                className={styles.eyeIcon}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <button className={styles.submit} type="submit" disabled={loading}>
            {loading ? "Changing..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChangePaassword;
