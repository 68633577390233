import React, { useRef, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import styles from './Input.module.css';

function PasswordInput({ value, onChange }) {
  const [showPassword, setShowPassword] = useState(false); // State for toggling visibility
  const valueInputRef = useRef(null);

  const handleValueChange = (e) => {
    onChange(e);
    if (e.target.value.trim().length > 0) {
      valueInputRef.current.classList.add(styles.notEmpty);
    } else {
      valueInputRef.current.classList.remove(styles.notEmpty);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className={styles.inputWrapper}>
      <input
        ref={valueInputRef}
        type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
        onChange={handleValueChange}
        className={styles.input}
        inputMode="password"
        name="password"
        placeholder="password"
        value={value}
        autoFocus
      />
      <span
        className={styles.eyeIcon}
        onClick={togglePasswordVisibility}
        aria-label="Toggle password visibility"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Eye icon */}
      </span>
    </div>
  );
}

export default PasswordInput;
