import React, { useEffect, useRef } from "react";
import styles from "./AuthBackground.module.css";
import { gsap } from "../../../plugins/gsap.min.js";
import { MorphSVGPlugin } from "../../../plugins/MorphSVGPlugin.min.js";

gsap.registerPlugin(MorphSVGPlugin);

const AuthBackground = ({ route }) => {
  const wave1Ref = useRef(null);
  const wave2Ref = useRef(null);
  const wave3Ref = useRef(null);
  const wave4Ref = useRef(null);

  useEffect(() => {
    if (wave1Ref.current && wave2Ref.current && wave3Ref.current && wave4Ref.current) {
      if (route === "/auth/login") {
        gsap.to(wave1Ref.current, {
          morphSVG: {
            shape: "M581.791 374.5C874 52.4997 1259 -17.0003 1259 -17.0003C1259 -17.0003 824.386 -73.7616 581.791 -182.856C109.155 -395.4 -668.231 25.9991 -538.529 527.772C-496.636 689.841 -308.923 889.75 -308.923 889.75C-308.923 889.75 37.2894 1114.79 253.026 1040.92C502.56 955.48 404.475 569.894 581.791 374.5Z",
          },
          duration: 2,
          ease: "power2.inOut",
        });
        gsap.to(wave2Ref.current, {
          x: 50,
          duration: 2,
          ease: "power2.inOut",
        });
        gsap.to(wave3Ref.current, {
          opacity: 0.5,
          duration: 2,
          ease: "power2.inOut",
        });
      } else if (route === "/auth/register") {
        gsap.to(wave1Ref.current, {
          morphSVG: {
            shape: "M611.563 156.677C676.853 66.6861 718.904 -160.309 718.904 -160.309C718.904 -160.309 591.879 -363.709 282.582 -356.548C-26.7136 -349.386 195.743 39.6836 -228.883 327.847C-653.508 616.011 -29.5886 515.536 -29.5886 515.536C-29.5886 515.536 138.981 409.002 256.32 331.998C373.659 254.993 511.775 294.216 611.563 156.677Z",
          },
          duration: 2,
          ease: "power2.inOut",
        });
        gsap.to(wave2Ref.current, {
          scale: 1.2,
          duration: 1.5,
          ease: "power2.inOut",
        });
        gsap.to(wave4Ref.current, {
          opacity: 1,
          duration: 1.5,
          ease: "power2.inOut",
        });
      } else if (route === "/auth/forgot-password") {
        gsap.to(wave1Ref.current, {
          morphSVG: {
            shape: "M1522.17 711.506C1521.22 644.877 1568.52 547.348 1568.52 547.348C1568.52 547.348 1393.44 527.583 1307.5 584.1C1140.07 694.208 1116.64 1034.71 1307.5 1096.18C1369.15 1116.03 1473.38 1096.18 1473.38 1096.18C1473.38 1096.18 1618.69 1029.86 1639.27 944.268C1663.07 845.271 1523.63 813.339 1522.17 711.506Z",
          },
          duration: 1.5,
          rotate: `2deg`,
          x: -50,
          y: '-60vh',
          ease: "power2.inOut",
        });
        gsap.to([wave2Ref.current, wave3Ref.current], {
          x: -150,
          duration: 2,
          ease: "power2.inOut",
        });
      }
    }
  }, [route]);

  return (
    <div className={styles["background"]}>
      <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2560 1080" preserveAspectRatio="xMidYMid slice">
        <g clipPath="url(#clip0_329_2526)">
          <mask id="mask0_329_2526" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="2560" height="1080">
            <path d="M2560 0H0V1080H2560V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_329_2526)">
            <path d="M2560 -154.779H0V1118.45H2560V-154.779Z" fill="#F1F9FB" />
            <path
              ref={wave1Ref}
              d="M985.043 330.784C1479.79 -86.71 2131.64 -176.821 2131.64 -176.821C2131.64 -176.821 1395.79 -250.416 985.043 -391.863C184.813 -667.44 -1131.4 -121.07 -911.795 529.51C-840.865 739.643 -523.044 998.837 -523.044 998.837C-523.044 998.837 63.1352 1290.62 428.404 1194.84C850.895 1084.06 684.826 584.124 985.043 330.784Z"
              fill="#C9EAEC"
            />
            <path
              ref={wave2Ref}
              d="M-812.736 -442.681C-749.894 -543.184 -431.474 -660.978 -431.474 -660.978C-431.474 -660.978 68.6102 -584.259 460.129 -239.733C851.648 104.793 -78.1688 -19.2466 1.67322 541.119C81.5153 1101.48 -566.809 379.493 -566.809 379.493C-566.809 379.493 -611.433 159.504 -637.813 5.47888C-664.192 -148.545 -908.779 -289.077 -812.736 -442.681Z"
              fill="#7FC7D9"
              fillOpacity="0.2"
            />
            <path
              ref={wave3Ref}
              d="M1035.45 48.3626C1145.99 -68.316 1217.19 -362.63 1217.19 -362.63C1217.19 -362.63 1002.12 -626.35 478.446 -617.066C-45.2299 -607.78 331.417 -103.326 -387.527 270.295C-1106.47 643.918 -50.0975 513.646 -50.0975 513.646C-50.0975 513.646 235.312 375.518 433.981 275.677C632.65 175.835 866.497 226.69 1035.45 48.3626Z"
              fill="#7FC7D9"
              fillOpacity="0.1"
            />
            <path
              ref={wave4Ref}
              d="M3135.17 782.39C3251 660.12 2957.75 266.876 2957.75 266.876C2957.75 266.876 3091.84 -215.723 2543.07 -205.993C1994.29 -196.263 2029.53 538.603 1276.14 930.129C522.751 1321.66 1854.72 1261.33 1854.72 1261.33C1854.72 1261.33 2124.88 1293.97 2314.98 1203.86C2505.08 1113.76 2925.33 1022.52 3135.17 782.39Z"
              fill="#C9EAEC"
              fillOpacity="0.2"
            />
            <path
              d="M2577.22 767.733C2576.35 710.546 2627.85 625.967 2627.85 625.967C2627.85 625.967 2434.82 607.476 2354.96 659.63C2182.43 766.85 2161.08 1117.18 2354.96 1179.06C2418.89 1199.49 2534.7 1179.06 2534.7 1179.06C2534.7 1179.06 2721.87 1116.68 2740.29 1027.95C2762.79 928.018 2577.22 892.683 2577.22 767.733Z"
              fill="#C9EAEC"
              fillOpacity="0.2"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_329_2526">
            <rect width="2560" height="1080" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default AuthBackground;
