import React from "react";
import styles from "./EmployeeBox.module.css";
import imageUrl from "../../../../assets/images/default_user_img_report.png";
function EmployeeBox({ employee }) {
  const user = employee?.report?.user;
  const is_anonymous = employee?.anonymus;
  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <img
          src={(!is_anonymous && user?.[`profile_pic_path`]) || imageUrl}
          alt={(!is_anonymous && user?.[`first_name`]) || "Anonymous"}
        />
      </div>
      <div className={styles.title}>
        {(!is_anonymous && user?.[`first_name`]) || "Anonymous"}
      </div>
    </div>
  );
}

export default EmployeeBox;
