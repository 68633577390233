import React from "react";
import styles from "./BurnoutLinerChart.module.css";
import ProgressBar from "@ramonak/react-progress-bar";
function BurnoutLinerChart({ data }) {
  console.log(data?.report?.burnout);
  return (
    <div className={styles.wrapper}>
      <ProgressBar
        customLabel="Burnout"
        height="20px"
        labelSize="12px"
        labelAlignment="outside"
        borderRadius="8px"
        baseBgColor="rgba(245, 245, 245, 1)"
        completed={data?.report?.burnout}
        labelColor="rgba(97, 97, 97, 1)"
        bgColor="rgba(54, 84, 134, 1)"
        animateOnRender="true"
      />
    </div>
  );
}

export default BurnoutLinerChart;
