export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePassword = (password) => {
  const passwordMinLength = 8;
  return password.length >= passwordMinLength;
};

export const validateNumber = (number) => {
  const numberRegex = /^\d+$/;
  return numberRegex.test(number);
};

export const validateName = (name) => {
  return name.trim().length > 0;
};

export const validatePhone = (phone) => {
  const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(phone);
};

export const validateEmployeeNumber = (employeeNumber) => {
  const employeeNumberRegex = /^\d+$/;
  return employeeNumberRegex.test(employeeNumber);
};

export const validateDepartment = (department) => {
  return department.trim().length > 0;
};

export const validateSection = (section) => {
  return section.trim().length > 0;
};

export const validateTeam = (team) => {
  return team.trim().length > 0;
};

export const validateJobTitle = (jobTitle) => {
  return jobTitle.trim().length > 0;
};

export const validate = (name, value) => {
  let errors = {};

  if (!value) return errors; // Skip validation if the field is empty

  switch (name) {
    case "fullName":
      if (!validateName(value)) {
        errors.fullName = "Full Name is required";
      }
      break;
    case "first_name":
      if (!validateName(value)) {
        errors.first_name = "your first name is required";
      }
      break;
    case "email":
      if (!validateEmail(value)) {
        errors.email = "Email address is invalid";
      }
      break;
    case "jobTitle":
      if (!validateJobTitle(value)) {
        errors.jobTitle = "Job Title is required";
      }
      break;
    case "department":
      if (!validateDepartment(value)) {
        errors.department = "Department is required";
      }
      break;
    case "section":
      if (!validateSection(value)) {
        errors.section = "Section is required";
      }
      break;
    case "team":
      if (!validateTeam(value)) {
        errors.team = "Team is required";
      }
      break;
    case "password":
      if (!validatePassword(value)) {
        errors.password = "Password must be at least 8 characters";
      }
      break;
    case "phone":
      if (!validatePhone(value)) {
        errors.phone = "Phone number must be 10 digits";
      }
      break;
    case "phone_number":
      if (!validatePhone(value)) {
        errors.phone_number = "Phone number must be 10 digits";
      }
      break;
    case "company_number":
      if (!validatePhone(value)) {
        errors.company_number = "Phone number must be 10 digits";
      }
      break;
    case "employeeNumber":
      if (!validateEmployeeNumber(value)) {
        errors.employeeNumber = "Employee Number is required";
      }
      break;
    default:
      break;
  }

  return errors;
};

export const validateForm = (data) => {
  let errors = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      errors = { ...errors, ...validate(key, data[key]) };
    }
  }
  return errors;
};

export const validateFormOnSubmit = (data) => {
  let errors = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (!data[key].trim()) {
        errors[key] =
          `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
      } else {
        errors = { ...errors, ...validate(key, data[key]) };
      }
    }
  }
  return errors;
};
