import React from "react";
import styles from "./MoodTrackerWidget.module.css";
import TotalMoodTracker from "./TotalMoodTracker";
import TotalMoodTrackerHeader from "./TotalMoodTracker/TotalMoodTrackerHeader";
function MoodTrackerWidget({ data }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Mood Tracker</h2>
        <span>
          <TotalMoodTrackerHeader MoodData={data} />
        </span>
      </div>
      <TotalMoodTracker MoodData={data} />
    </div>
  );
}

export default MoodTrackerWidget;
