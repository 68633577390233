import React from "react";
import styles from "./DescriptionBox.module.css";
function DescriptionBox({ employee }) {
  const description = employee?.report?.description;

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <p
          className={styles.descriptionText}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className={styles.practices}>
        <div className={styles.title}>Practices</div>
        <div className={styles.practice}>
          <span>{employee?.report.rec_practice}</span>
          <span>{employee?.report.rec_exercise.exercise_type}</span>
        </div>
      </div>
    </div>
  );
}

export default DescriptionBox;
