import React from "react";
import styles from "./ReportList.module.css";
import ReportItem from "../ReportItem";
import { useReport } from "../../../contexts/ReportContext";
import Loader from "../../common/Loader";
import EmptyList from "../../report/EmptyList";
function ReportList({ filteredReports }) {
  const { loading } = useReport();
  if (loading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {filteredReports?.length > 0 ? (
          filteredReports?.map((report) => {
            return <ReportItem key={report.report.id} data={report} />;
          })
        ) : (
          <div>
            <EmptyList />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportList;
