import React, { createContext, useContext, useState, useEffect } from "react";
import { manageReportService } from "../services/manageReportService";

const ReportContext = createContext();

export const ReportProvider = ({ children }) => {
  const [report, setReport] = useState(null);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReport = async (reportId) => {
    setLoading(true);
    try {
      const data = await manageReportService.fetchReport(reportId);
      console.log(data);
      if (data.message === "success") {
        console.log(data.data);
        setReport(data.data);
      } else {
        console.error("Failed to fetch report:", data);
      }
    } catch (error) {
      console.error("Failed to fetch report:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAllReports = async () => {
    setLoading(true);
    try {
      const data = await manageReportService.fetchAllReports();
      if (data.message === "success") {
        console.log(data.data);
        setReports(data.data);
      } else {
        console.error("Failed to fetch reports:", data);
      }
    } catch (error) {
      console.error("Failed to fetch reports:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllReports();
  }, []);

  return (
    <ReportContext.Provider
      value={{
        report,
        reports,
        loading,
        fetchReport,
        fetchAllReports,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = () => useContext(ReportContext);
