import styles from "./Header.module.css";
import Logo from "../common/Logo";
import Notifications from "../Notifications";
import ProfileButton from "../profile/ProfileButton";

function Header({ toggleSidebar }) {
  // const { darkMode, toggleDarkMode } = useContext(ThemeContext);

  // useEffect(() => {
  //   if (darkMode) {
  //     console.log("dark mode enabled");
  //     document.documentElement.classList.add("dark-mode");
  //   } else {
  //     document.documentElement.classList.remove("dark-mode");
  //   }
  // }, [darkMode]);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <button className={styles.sidebarToggle} onClick={toggleSidebar}>
          ☰
        </button>
        <Logo />
      </div>
      <div className={styles.right}>
        {/* <button onClick={toggleDarkMode} className={styles.toggleButton}>
          Switch to {darkMode ? "Light" : "Dark"} Mode
        </button> */}
        <ProfileButton />
        <Notifications />
        
      </div>
    </div>
  );
}

export default Header;
