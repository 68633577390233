import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./EmailInput.module.css";

const EmailInput = React.forwardRef(


  ({ label, placeholder, error, ...props }, ref) => (
    <div className={styles.inputWrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <input
        type="email"
        ref={ref}
        placeholder={placeholder}
        className={`${styles.input} ${error ? styles.inputError : ""}`}
        {...props}
      />
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  )
);

EmailInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.object,
};

export default EmailInput;
