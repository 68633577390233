import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "./GeneralLayout.module.css";
import Header from "../../Header";
import SidebarLayout from "../SidebarLayout";
import Navbar from "../../Navbar";
import LogOutButton from "../../common/LogOutButton";
import Backdrop from "../../common/Backdrop";
import { EmployeeProvider } from "../../../contexts/EmployeeContext";

const GeneralLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <EmployeeProvider>
      <div className={styles.outlet}>
        <Header toggleSidebar={toggleSidebar} />
        <Backdrop isOpen={sidebarOpen} onClick={toggleSidebar} />
        <div className={styles.body}>
          <SidebarLayout isOpen={sidebarOpen}>
            <Navbar toggleSidebar={toggleSidebar} />
            <LogOutButton />
          </SidebarLayout>
          <div className={styles.layout}>
            <Outlet />
          </div>
        </div>
      </div>
    </EmployeeProvider>
  );
};

export default GeneralLayout;
