import React from "react";
import styles from "./NotificationPage.module.css";
import NotificationList from "../../components/Notification/NotificationList";
import { ReportProvider } from "../../contexts/ReportContext";
function NotificationPage() {
  return (
    <ReportProvider>
      <div className={styles.container}>
        <div className={styles.header}></div>
        <div className={styles.notifications}>
          <NotificationList />
        </div>
      </div>
    </ReportProvider>
  );
}

export default NotificationPage;
