import React, { useState } from "react";
import styles from "./ReportPage.module.css";
import SearchReport from "../../components/report/SearchReport";
import ReportList from "../../components/report/ReportList";
import { ReportProvider } from "../../contexts/ReportContext";

function ReportPage() {
  const [filteredReports, setFilteredReports] = useState([]);
  return (
    <ReportProvider>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.left}>
            <SearchReport setFilteredReports={setFilteredReports} />
          </div>
          <div className={styles.right}></div>
        </div>
        <div className={styles.body}>
          <ReportList filteredReports={filteredReports} />
          <div className={styles.scrollbar} id="customScrollbar">
            <div className="scrollbar-thumb" id={styles.scrollbarThumb}></div>
          </div>
        </div>
      </div>
    </ReportProvider>
  );
}

export default ReportPage;
