import React, { useEffect } from "react";
import styles from "./ReportDetailLayout.module.css";
import EmployeeBox from "../../report/ReportDetail/EmployeeBox";
import StatusBox from "../../report/ReportDetail/StatusBox";
import DescriptionBox from "../../report/ReportDetail/DescriptionBox";
import NoteBox from "../../report/ReportDetail/NoteBox";
import SymptomBox from "../../report/ReportDetail/SymptomBox";
import { useReport } from "../../../contexts/ReportContext";
import BurnoutLinerChart from "../../dashboard/BurnoutWidget/BurnoutLinerChart";
import Loader from "../../common/Loader";
function ReportDetailLayout() {
  const { fetchReport, report, loading } = useReport();

  const params = new URLSearchParams(window.location.search);
  const reportId = params.get("employee_id");

  useEffect(() => {
    const getReport = async () => {
      await fetchReport(reportId);
    };

    if (reportId) {
      getReport();
    }
  }, []);

  if (loading) {
    return (
      <div className={styles.center}>
        <Loader />
      </div>
    );
  }

  if (!report) {
    return <div>No report found.</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.box}>
          <EmployeeBox employee={report} />
        </div>
        <div className={styles.box}>
          <StatusBox employee={report} />
          <BurnoutLinerChart data={report} />
        </div>
        <div className={styles.box}>
          <SymptomBox employee={report} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.box}>
          <DescriptionBox employee={report} />
        </div>
        <div className={` ${styles.NoteBox}`}>
          <NoteBox employee={report} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.back}>
          <a href="/reports">Back to Reports</a>
        </div>
      </div>
    </div>
  );
}

export default ReportDetailLayout;
