import React, { createContext, useContext, useState, useEffect } from "react";
import { dashboardService } from "../services/dashboardService";

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchDashboerdData = async () => {
    setLoading(true);
    try {
      const data = await dashboardService.fetchDashboerdData();
      if (data.message === "success") {
        setData(data.data);
      }
    } catch (error) {
      console.error("Failed to fetch Dashboerd Data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboerdData();
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        data,
        loading,
        fetchDashboerdData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => useContext(DashboardContext);
