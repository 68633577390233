import React from 'react'
import styles from './Modal.module.css'
import propTypes from 'prop-types'
import ModalBackdrop from './ModalBackdrop'
function index({children, onClose }) {
  return (
    <div className={styles.wrapper}>
        <ModalBackdrop onClick={onClose} />
        <div className={styles.modal}>
            {children}
        </div>
    </div>
  )
}
index.propTypes = {
    children: propTypes.node.isRequired,
    onClose: propTypes.func.isRequired
};
export default index