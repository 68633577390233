import React from "react";
import Styles from "./StructureList.module.css";
import Department from "../Department";
import { useStructure } from "../../../contexts/StructureContext";
import Loader from "../../common/Loader";
function StructureList() {
  const { departments, loading } = useStructure();

  if (loading) {
    return (
      <div className={Styles.loading}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.list}>
        {departments.map((department) => (
          <Department key={department.id} department={department} />
        ))}
      </div>
    </div>
  );
}

export default StructureList;
