import React, { useState, useEffect } from "react";
import styles from "./EditProfileForm.module.css";
import InputField from "../../common/InputField";
import PhoneNumberInput from "../../common/PhoneNumberInput";
import ImagePicker from "../../common/ImagePicker";
import SubmitButton from "../../common/buttons/SubmitButton";
import { put } from "../../../utils/api";
import { toast } from "react-toastify";
import { validateNumber } from "../../../utils/validation";
import { authService } from "../../../services/authService";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import Loader from '../../common/Loader'
function EditProfileForm() {
  const { user, initializeToken } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_number: "",
    company_name: "",
    job_title: "",
    phone: "",
  });

  useEffect(() => {
    setFormData({
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      company_number: user?.company_number || "",
      company_name: user?.company_name || "",
      job_title: user?.job_title || "",
      phone: user?.phone || "",
    });
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const {
        first_name,
        last_name,
        company_number,
        company_name,
        job_title,
        phone,
      } = formData;

      if (
        !first_name ||
        !last_name ||
        !company_number ||
        !company_name ||
        !job_title ||
        !phone
      ) {
        toast.error("Please fill in all fields");
        return;
      }

      if (!validateNumber(company_number) || !validateNumber(phone)) {
        toast.error(
          "Please enter valid numbers for company number and phone number"
        );
        return;
      }

      const response = await put("account/info/", formData);
      console.log("API Response:", response);

      await authService.updateUserInfo(formData);

      setFormData({
        first_name: "",
        last_name: "",
        company_number: "",
        company_name: "",
        job_title: "",
        phone: "",
      });

      initializeToken();
      navigate("/dashboard");
    } catch (error) {
      toast.error(error);
    }
  };
  if (!user) {
    return <div className={styles.container}><Loader /></div>
  }
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <ImagePicker />
        <div className={styles.inputGroupRow}>
          <InputField
            type="text"
            placeholder="First name"
            name="first_name"
            value={formData?.first_name}
            onChange={handleChange}
          />
          <InputField
            type="text"
            placeholder="Last name"
            name="last_name"
            value={formData?.last_name}
            onChange={handleChange}
          />
        </div>
        <div className={styles.inputGroupCol}>
          <PhoneNumberInput
            placeholder="Company number"
            name="company_number"
            value={formData.company_number}
            onChange={handleChange}
          />
          <InputField
            type="text"
            placeholder="Company name"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
          />
        </div>
        <div className={styles.inputGroupCol}>
          <InputField
            type="text"
            placeholder="Job title"
            name="job_title"
            value={formData.job_title}
            onChange={handleChange}
          />
          <PhoneNumberInput
            placeholder="Phone number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <SubmitButton action={handleSubmit} title={"Continue"} />
        <div className={styles.linkWrapper}>
          <Link className={styles.link} to="/auth/forgot-password">
            Change Password
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EditProfileForm;
