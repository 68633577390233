import React from "react";
import styles from "./ReportWidget.module.css";
import ReportItem from "./ReportItem";
import { useNavigate } from "react-router-dom";
function ReportWidget({ data }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/reports");
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Today’s Reports</h2>
        <span className={styles.more}>
          <span onClick={handleClick}> more</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M6 12.5L10 8.5L6 4.5"
              stroke="#7FC7D9"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className={styles.content}>
        {data?.map((report) => {
          return <ReportItem key={report.report.id} data={report} />;
        })}
        {data?.length === 0 && (
          <div className={styles.empty}>
            <p>No reports submitted today</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportWidget;
