import React from "react";
import url from "../../assets/images/bell.png";
import styles from "./Notification.module.css";
import { useNavigate } from "react-router-dom";
function Notification() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/notification");
  };
  return (
    <div
      className={styles.bell}
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <img src={url} alt="Notifications" />
    </div>
  );
}

export default Notification;
