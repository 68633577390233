import React from 'react';
import styles from './AddNewBtn.module.css';
import SelectType from '../SelectType';
import { useStructure } from '../../../contexts/StructureContext';

function AddNewBtn() {
  const {showSelectTypeModal, setShowSelectTypeModal} = useStructure();
  function toggleSelectTypeModal() {
    setShowSelectTypeModal(!showSelectTypeModal);
  }

  return (
   <>
    <div className={styles.wrapper}>
      <div onClick={toggleSelectTypeModal} className={styles.btn}>+ Add New</div>
      {showSelectTypeModal && <SelectType />}
    </div>
    {showSelectTypeModal && <div onClick={toggleSelectTypeModal} className={styles.backdrop}></div>}
    </>
  );
}

export default AddNewBtn;
