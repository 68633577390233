import { getAccessToken, setAccessToken } from "./indexedDBUtils";

const BASE_URL = "https://api.monday-blues.com/api/v1";

// Initialize the access token from IndexedDB
let accessToken = await getAccessToken();

export async function request(url, options = {}, includeToken = true, isFormData = false) {
  accessToken = await getAccessToken();
  const headers = options.headers || {};
  
  if (includeToken && accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  // Don't set "Content-Type" for FormData, as the browser will handle it
  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }

  options.headers = headers;

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function get(path, includeToken = true) {
  const url = `${BASE_URL}/${path}`;
  return request(url, {}, includeToken);
}

export async function post(path, data, includeToken = true, isFormData = false, customHeaders = {}) {
  const url = `${BASE_URL}/${path}`;
  const options = {
    method: "POST",
    headers: {
      ...customHeaders,  
    },
    body: isFormData ? data : JSON.stringify(data),
  };

  if (!isFormData && !customHeaders["Content-Type"]) {
    options.headers["Content-Type"] = "application/json";
  }

  return request(url, options, includeToken, isFormData);
}


export async function put(path, data, includeToken = true) {
  const url = `${BASE_URL}/${path}`;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return request(url, options, includeToken);
}

export async function patch(path, data, includeToken = true) {
  const url = `${BASE_URL}/${path}`;
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return request(url, options, includeToken);
}

export async function del(path, includeToken = true) {
  const url = `${BASE_URL}/${path}`;
  const options = {
    method: "DELETE",
  };
  return request(url, options, includeToken);
}

export async function authenticate(path, credentials) {
  const url = `${BASE_URL}/${path}`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  };
  const response = await request(url, options, false);
  await setAccessToken(response.accessToken);
  return response;
}
