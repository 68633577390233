// notificationHelpers.js
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Function to navigate to the report page
const navigateToReportPage = (projectId) => {
  window.location.href = `/report-detail/?employee_id=${projectId}`;
};

export const toastNotification = ({
  title,
  description,
  status,
  projectId,
}) => {
  const options = {
    type: status || "default",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClick: () => navigateToReportPage(projectId), // Navigate to report page on click
  };

  toast(`${title} - ${description}`, options);
};

export const sendNativeNotification = ({ title, body, projectId }) => {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notifications.");
    return;
  }

  if (Notification.permission === "granted") {
    const notification = new Notification(title, { body });

    notification.onclick = () => {
      window.focus();
      navigateToReportPage(projectId);
    };
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const notification = new Notification(title, { body });

        notification.onclick = () => {
          window.focus();
          browserNavigateToReportPage(projectId);
        };
      }
    });
  }
};

// Function to navigate to the report page
const browserNavigateToReportPage = (projectId) => {
  window.location.href = `/reports/${projectId}`;
};
