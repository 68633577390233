import React, { useEffect, useState } from "react";
import styles from "./AddEmploeyeModal.module.css";
import { useEmployee } from "../../../contexts/EmployeeContext";
import Modal from "../../common/Modal";
import { validateForm, validateFormOnSubmit } from "../../../utils/validation";
import { useStructure } from "../../../contexts/StructureContext";
import { toast } from "react-toastify";
import { manageEmployeeService } from "../../../services/manageEmployeeService";

function AddEmployeeModal() {
  const { showAddEmployeeModal, setShowAddEmployeeModal, fetchAllEmployees } =
    useEmployee();
  const { departments } = useStructure();
  const [employeeData, setEmployeeData] = useState({
    first_name: "",
    email: "",
    job_title: "",
    department: "",
    section: "",
    team: "",
    password: "",
    phone: "",
    employee_number: "",
  });

  const [sections, setSections] = useState([]);
  const [teams, setTeams] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleDepartmentChange(e) {
    const selectedDepartment = departments.find(
      (dep) => dep.id === parseInt(e.target.value)
    );
    setEmployeeData((prevData) => ({
      ...prevData,
      department: e.target.value,
      section: "",
      team: "",
    }));
    setSections(selectedDepartment ? selectedDepartment.sections : []);
    setTeams([]);
    setErrors((prevErrors) => ({ ...prevErrors, department: "" })); // Clear department error
  }

  function handleSectionChange(e) {
    const selectedSection = sections.find(
      (sec) => sec.id === parseInt(e.target.value)
    );
    setEmployeeData((prevData) => ({
      ...prevData,
      section: e.target.value,
      team: "",
    }));
    setTeams(selectedSection ? selectedSection.teams : []);
    setErrors((prevErrors) => ({ ...prevErrors, section: "" })); // Clear section error
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setEmployeeData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      const formErrors = validateForm(updatedData);
      setErrors(formErrors);
      return updatedData;
    });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear specific field error
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const formErrors = validateFormOnSubmit(employeeData);

    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const res = await manageEmployeeService.addEmployee(employeeData);
        toast.info(res.message);
        fetchAllEmployees();
        setShowAddEmployeeModal(false);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(formErrors);
      toast.error("Please fix the form errors.");
    }
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log(employeeData);
    }
  }, [errors, employeeData, isSubmitting]);

  const setClass = (key) => {
    return errors[key] ? styles.error : "";
  };

  return (
    <div>
      {showAddEmployeeModal && (
        <Modal onClose={() => setShowAddEmployeeModal(false)}>
          <div className={styles.modalContent}>
            <h2>Add Employee</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.row}>
                <div className={styles.field}>
                  <input
                    onChange={handleChange}
                    className={setClass("first_name")}
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder="First Name"
                    value={employeeData.first_name}
                  />
                  {errors.first_name && (
                    <span className={styles.errorText}>
                      {errors.first_name}
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <input
                    onChange={handleChange}
                    className={setClass("email")}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={employeeData.email}
                  />
                  {errors.email && (
                    <span className={styles.errorText}>{errors.email}</span>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <input
                    onChange={handleChange}
                    className={setClass("job_title")}
                    type="text"
                    id="job_title"
                    name="job_title"
                    placeholder="Job Title"
                    value={employeeData.job_title}
                  />
                  {errors.job_title && (
                    <span className={styles.errorText}>{errors.job_title}</span>
                  )}
                </div>
                <div className={styles.field}>
                  <select
                    onChange={handleDepartmentChange}
                    className={setClass("department")}
                    id="department"
                    name="department"
                    value={employeeData.department}
                  >
                    <option value="">Select Department</option>
                    {departments.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </select>
                  {errors.department && (
                    <span className={styles.errorText}>
                      {errors.department}
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <select
                    onChange={handleSectionChange}
                    className={setClass("section")}
                    id="section"
                    name="section"
                    value={employeeData.section}
                    disabled={!sections.length}
                  >
                    <option value="">Select Section</option>
                    {sections.map((section) => (
                      <option key={section.id} value={section.id}>
                        {section.title}
                      </option>
                    ))}
                  </select>
                  {errors.section && (
                    <span className={styles.errorText}>{errors.section}</span>
                  )}
                </div>
                <div className={styles.field}>
                  <select
                    onChange={handleChange}
                    className={setClass("team")}
                    id="team"
                    name="team"
                    value={employeeData.team}
                    disabled={!teams.length}
                  >
                    <option value="">Select Team</option>
                    {teams.map((team) => (
                      <option key={team.id} value={team.id}>
                        {team.title}
                      </option>
                    ))}
                  </select>
                  {errors.team && (
                    <span className={styles.errorText}>{errors.team}</span>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <input
                    onChange={handleChange}
                    className={setClass("password")}
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={employeeData.password}
                  />
                  {errors.password && (
                    <span className={styles.errorText}>{errors.password}</span>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <input
                    onChange={handleChange}
                    className={setClass("phone")}
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    value={employeeData.phone}
                  />
                  {errors.phone && (
                    <span className={styles.errorText}>{errors.phone}</span>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <input
                    onChange={handleChange}
                    className={setClass("employee_number")}
                    type="text"
                    id="employee_number"
                    name="employee_number"
                    placeholder="Employee Number"
                    value={employeeData.employee_number}
                  />
                  {errors.employee_number && (
                    <span className={styles.errorText}>
                      {errors.employee_number}
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Saving..." : "Save"}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AddEmployeeModal;
