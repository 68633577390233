import React from "react";
import styles from "./MentalHealthModal.module.css";
import TotalMentalHealthRate from "../TotalMentalHealthRate";
import ProgressBar from "@ramonak/react-progress-bar";
function MentalHealthModal({ data }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.chart}>
        <TotalMentalHealthRate data={data} />
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Stress"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Workload Concerns"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Mood Fluctuations"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Anxiety"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Fatigue"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Demotivation"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Social Withdrawal"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Cognitive Challenges"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Health Complaints"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Requests for Help"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <ProgressBar
            customLabel="Work Conflicts"
            labelSize="12px"
            labelAlignment="outside"
            animateOnRender="true"
            height="20px"
            borderRadius="8px"
            baseBgColor="rgba(245, 245, 245, 1)"
            completed={data?.overal_report?.anxiety.value}
            labelColor="rgba(97, 97, 97, 1)"
            bgColor="rgba(127, 199, 217, 1)"
          />
        </div>
      </div>
    </div>
  );
}

export default MentalHealthModal;
