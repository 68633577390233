import React, { useState } from "react";
import styles from "./FilterBtn.module.css";
import { useEmployee } from "../../../contexts/EmployeeContext";
import { useStructure } from "../../../contexts/StructureContext";

function FilterBtn({ setFilteredEmployees }) {
  const [toggled, setToggled] = useState(false);
  const { employees } = useEmployee();
  const { departments } = useStructure();

  const [filterCriteria, setFilterCriteria] = useState({
    department: "",
    section: "",
    team: "",
    job_title: "",
  });

  // Flatten all sections and teams into arrays for filtering
  const allSections = departments.flatMap((department) => department.sections);
  const allTeams = allSections.flatMap((section) => section.teams);

  // Handle toggle of the filter button
  const handleToggle = () => {
    setToggled(!toggled);
  };

  // Handle click outside the modal to close it
  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      handleToggle();
    }
  };

  // Handle filter criteria change
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Apply filters when the form is submitted
  const handleFilterSubmit = (e) => {
    e.preventDefault();

    // Filter employees based on criteria
    const filtered = employees.filter((employee) => {
      return (
        (filterCriteria.department
          ? employee.team.section.department.id ===
            parseInt(filterCriteria.department)
          : true) &&
        (filterCriteria.section
          ? employee.team.section.id === parseInt(filterCriteria.section)
          : true) &&
        (filterCriteria.team
          ? employee.team.id === parseInt(filterCriteria.team)
          : true) &&
        (filterCriteria.job_title
          ? employee.job_title
              .toLowerCase()
              .includes(filterCriteria.job_title.toLowerCase())
          : true)
      );
    });

    // Update the filtered employee list
    setFilteredEmployees(filtered);
    handleToggle(); // Close the modal
  };

  return (
    <>
      <div className={styles.svgContainer}>
        <div onClick={handleToggle} className={styles.svgContainer}>
          <svg
            className={`${styles.svg} ${toggled ? styles.toggled : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              className={styles.path}
              d={toggled ? "M6 6L18 18M6 18L18 6" : "M6 12H18M3 6H21M9 18H15"}
              stroke="#365486"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        {toggled && (
          <div className={styles.modal} onClick={handleOutsideClick}>
            <div className={styles.modalContent}>
              <form onSubmit={handleFilterSubmit}>
                <div className={styles.row}>
                  <div className={styles.field}>
                    <select
                      onChange={handleFilterChange}
                      name="department"
                      value={filterCriteria.department}
                    >
                      <option value="">Select Department</option>
                      {departments?.map((department) => (
                        <option key={department.id} value={department.id}>
                          {department.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={styles.field}>
                    <select
                      onChange={handleFilterChange}
                      name="section"
                      value={filterCriteria.section}
                    >
                      <option value="">Select Section</option>
                      {allSections.map((section) => (
                        <option key={section.id} value={section.id}>
                          {section.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={styles.field}>
                    <select
                      onChange={handleFilterChange}
                      name="team"
                      value={filterCriteria.team}
                    >
                      <option value="">Select Team</option>
                      {allTeams.map((team) => (
                        <option key={team.id} value={team.id}>
                          {team.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={styles.field}>
                    <input
                      onChange={handleFilterChange}
                      name="job_title"
                      value={filterCriteria.job_title}
                      placeholder="Job Title"
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <button type="submit">Apply Filters</button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      {toggled && (
        <div
          onClick={(e) => {
            e.preventDefault();
            handleToggle();
          }}
          className={styles.backdrop}
        ></div>
      )}
    </>
  );
}

export default FilterBtn;
