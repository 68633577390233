import React from 'react';
import styles from './ProfileButton.module.css';
import defaultImg from '../../../assets/images/default_user_img.png';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ROUTE_CONSTANTS from '../../../constants/ROUTE_CONSTANTS';

function Index() {


  const { user } = useAuth();
  const navigator = useNavigate();
  const userFirstName = user ? user['first_name'] || 'profile' : 'profile';
  const userLastName = user ? user['last_name'] || '' : '';
  const userData = {
    first_name: userFirstName,
    last_name: userLastName
};



  const handleClick = () => {
    const userProfileRoute = ROUTE_CONSTANTS.USER.GET_BY_DATA(userData);
    navigator(userProfileRoute);
  }
  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <img
        src={ user ? user['profile_pic_path'] || defaultImg : defaultImg}
        alt="Profile"
        className={styles.profileImage}
      />
      <span className={styles.profileText}>
        <span className={styles.profileTextLastName}>{userFirstName}</span>
        <span className={styles.profileTextFirstName}>{userLastName}</span>
      </span>
    </div>
  ); 
}

export default Index;
