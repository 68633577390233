import { get, put } from "../utils/api";

export const manageReportService = {
  fetchReport: async (reportId) => {
    return await get(`management/report/?report_id=${reportId}`, true);
  },

  fetchAllReports: async () => {
    return await get("management/report/", true);
  },

  sendNote: async (reportId, data) => {
    return await put(`management/report/?report_id=${reportId}`, data, true);
  },
};
