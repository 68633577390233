import React, { useEffect, useState } from "react";

const TotalMentalHealthRate = ({ data }) => {
  const [percentage, setPercentage] = useState(0);
  const targetPercentage = data?.mental_rate * 100;
  const size = 200;
  const strokeWidth = 12;
  const radius = (size - strokeWidth) / 2.5;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const animate = () => {
      if (percentage < targetPercentage) {
        setPercentage((prev) => Math.min(prev + 1, targetPercentage));
      }
    };
    const interval = setInterval(animate, 20);
    return () => clearInterval(interval);
  }, [percentage, targetPercentage]);

  return (
    <div style={{ position: "relative", width: size, height: size }}>
      <svg width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="none"
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="rgba(255, 167, 141, 1)"
          strokeWidth={strokeWidth}
          fill="rgba(255, 244, 241, 1)"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (percentage / 100) * circumference}
          strokeLinecap="round"
          style={{ transition: "stroke-dashoffset 0.5s ease" }}
        />
      </svg>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "24px",
          color: "rgba(255, 167, 141, 1)",
          fontWeight: "bold",
        }}
      >
        {percentage}%
      </div>
    </div>
  );
};

export default TotalMentalHealthRate;
