import React, { useState, useEffect } from "react";
import { useStructure } from "../../../contexts/StructureContext";
import styles from "./AddTeamForm.module.css";
import SaveButton from "../../common/buttons/SaveButton";
import { toast } from "react-toastify";
import { structureService } from "../../../services/structureService";

function AddTeamForm({ onClose }) {
  const [teamName, setTeamName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const { departments, addTeam, fetchData } = useStructure();
  const [newTeams, setNewTeams] = useState([]);

  const handleAdd = () => {
    if (teamName.trim() && selectedSection) {
      const newTeam = {
        section_id: parseInt(selectedSection, 10),
        teamName: teamName.trim(),
      };
      setNewTeams((prev) => [...prev, newTeam]);
      addTeam(selectedSection, teamName);
      setTeamName("");
    } else {
      toast.error(
        "Please select a department, section, and enter a team name."
      );
    }
  };

  const submitTeams = async () => {
    try {
      const teamsBySection = newTeams.reduce((acc, team) => {
        const { section_id, teamName } = team;
        if (!acc[section_id]) {
          acc[section_id] = [];
        }
        acc[section_id].push(teamName);
        return acc;
      }, {});

      const responses = [];

      for (const section_id of Object.keys(teamsBySection)) {
        const parsedSectionId = parseInt(section_id, 10);
        const teams = teamsBySection[parsedSectionId];

        try {
          const res = await structureService.addTeam(parsedSectionId, teams);
          responses.push(res.message);
          fetchData();
          toast.info(res.message);
        } catch (error) {
          console.error("Error:", error);
          toast.error(
            `Failed to add teams to section ${parsedSectionId}: ${error.message}`
          );
        }
      }

      return responses;
    } catch (error) {
      toast.error("Failed to submit teams: " + error.message);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitTeams();
      onClose();
    } catch (error) {
      toast.error("Failed to submit teams: " + error.message);
    }
  };

  const handleDelete = (teamIndex) => {
    setNewTeams((prev) => prev.filter((_, index) => index !== teamIndex));
  };

  useEffect(() => {
    setSelectedSection("");
  }, [selectedDepartment]);

  const sections = selectedDepartment
    ? departments.find(
        (department) => department.id === parseInt(selectedDepartment)
      )?.sections || []
    : [];

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <label htmlFor="department" className={styles.label}>
        Select Department
      </label>
      <select
        id="department"
        value={selectedDepartment}
        onChange={(e) => setSelectedDepartment(e.target.value)}
        className={styles.select}
      >
        <option value="">Select a department</option>
        {departments.map((department) => (
          <option key={department.id} value={department.id}>
            {department.name}
          </option>
        ))}
      </select>
      <label htmlFor="section" className={styles.label}>
        Select Section
      </label>
      <select
        id="section"
        value={selectedSection}
        onChange={(e) => setSelectedSection(e.target.value)}
        className={styles.select}
        disabled={!selectedDepartment}
      >
        <option value="">Select a section</option>
        {sections.map((section) => (
          <option key={section.id} value={section.id}>
            {section.title}
          </option>
        ))}
      </select>
      <label htmlFor="teamName" className={styles.label}>
        Team Name
      </label>
      <div className={styles.row}>
        <input
          type="text"
          id="teamName"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          className={styles.input}
        />
        <div className={styles.buttons}>
          <div onClick={handleAdd} className={styles.submitButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="13"
              viewBox="0 0 18 13"
              fill="none"
            >
              <path
                d="M17 1L6 12L1 7"
                stroke="#66BB6A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Add
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.teams}>
          {newTeams.map((team, index) => (
            <div key={index} className={styles.team}>
              <span>{team.teamName}</span>
              <div
                onClick={() => handleDelete(index)}
                className={styles.deleteButton}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 4L4 12"
                    stroke="#365486"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 4L12 12"
                    stroke="#365486"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </div>
      <SaveButton type="submit">Save</SaveButton>
    </form>
  );
}

export default AddTeamForm;
