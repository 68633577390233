// App.js
import React, { useEffect } from "react";
import Routes from "./Routes/index";
import { setupNotifications } from "./firebase";
import {
  toastNotification,
  sendNativeNotification,
} from "./helper/notificationHelpers";
import useVisibilityChange from "./hooks/useVisibilityChange";
import { ThemeProvider } from "./contexts/ThemeContext";
import "./utils/styles/variables.css";
function App() {
  const isForeground = useVisibilityChange();

  useEffect(() => {
    setupNotifications((message) => {
      const { title, body } = message.notification;
      const projectId = message.data.project_id;

      if (isForeground) {
        toastNotification({
          title,
          description: body,
          status: "info",
          projectId, // Pass projectId
        });
      } else {
        sendNativeNotification({
          title,
          body,
          projectId, // Pass projectId
        });
      }
    });
  }, [isForeground]);
  return (
    <ThemeProvider>
      <div className="App">
        <Routes />
      </div>
    </ThemeProvider>
  );
}

export default App;
