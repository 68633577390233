import React from "react";
import styles from "./InputField.module.css";

const InputField = ({ type, placeholder, value, onChange, name }) => {
  return (
    <div className={styles.inputFieldContainer}>
      <input
        className={styles.inputField}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
