import React from "react";
import styles from "./Navbar.module.css";
import MenuItem from "./MenuItem";
import {
  DashboardIcon,
  AddEmployeeIcon,
  ReportIcon,
  LeadScoreIcon,
  SetupStructureIcon,
} from "./MenuIcons";

function Navbar({ toggleSidebar }) {
  return (
    <div className={styles.navbar}>
      <MenuItem
        to="/dashboard"
        icon={<DashboardIcon />}
        title="Dashboard"
        onClick={toggleSidebar}
      />
      <MenuItem
        to="/addemployee"
        icon={<AddEmployeeIcon />}
        title="Add Employees"
        onClick={toggleSidebar}
      />
      <MenuItem
        to="/reports"
        icon={<ReportIcon />}
        title="Reports"
        onClick={toggleSidebar}
      />
      <MenuItem
        to="/lead-scoring"
        icon={<LeadScoreIcon />}
        title="Lead Scoring"
        onClick={toggleSidebar}
      />
      <MenuItem
        to="/setup-structure"
        icon={<SetupStructureIcon />}
        title="Setup Structure"
        onClick={toggleSidebar}
      />
    </div>
  );
}

export default Navbar;
