import React, { useEffect, useRef } from "react";
import gsap from "../../../../plugins/gsap.min";
import { MorphSVGPlugin } from "../../../../plugins/MorphSVGPlugin.min";
import styles from "./StatusBox.module.css";

gsap.registerPlugin(MorphSVGPlugin);

const svgs = [
  `<svg id="svg0" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M32 32C32 32 29 28 24 28C19 28 16 32 16 32M18 18H18.02M30 18H30.02M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z" stroke="#365486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  `<svg  ="svg1" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M16 30H32M16 18H20M28 18H32M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z" stroke="#365486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  `<svg id="svg2" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
<path d="M16 28C16 28 19 32 24 32C29 32 32 28 32 28M18 18H18.02M30 18H30.02M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z" stroke="#365486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  `<svg id="svg3" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
<path d="M18 18H18.02M30 18H30.02M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24ZM36 26C35.5228 28.8249 34.0507 31.3858 31.8499 33.2198C29.649 35.0539 26.8646 36.04 24 36C21.1354 36.04 18.351 35.0539 16.1501 33.2198C13.9493 31.3858 12.4772 28.8249 12 26H36Z" stroke="#365486" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
];

function StatusBox({ employee }) {
  const avatarRef = useRef();
  const moodLevel = employee?.report?.mood || 2;
  let mood;
  if (moodLevel === 0) {
    mood = "Sad";
  } else if (moodLevel === 1) {
    mood = "Annoyed";
  } else if (moodLevel === 2) {
    mood = "Natural";
  } else if (moodLevel === 3) {
    mood = "Happy";
  }
  useEffect(() => {
    if (avatarRef.current) {
      const initialSvgElement = new DOMParser().parseFromString(
        svgs[1],
        "image/svg+xml"
      ).documentElement;

      const newSvg = svgs[moodLevel];
      const newSvgElement = new DOMParser().parseFromString(
        newSvg,
        "image/svg+xml"
      ).documentElement;

      const tl = gsap.timeline();
      tl.to(avatarRef.current.firstChild.querySelector("path"), {
        morphSVG: initialSvgElement.querySelector("path"),
        duration: 1,
      }).to(avatarRef.current.firstChild.querySelector("path"), {
        morphSVG: newSvgElement.querySelector("path"),
        duration: 1,
      });
    }
  }, [moodLevel]);

  return (
    <div className={styles.container}>
      <div
        className={styles.avatar}
        ref={avatarRef}
        dangerouslySetInnerHTML={{ __html: svgs[1] }}
      />
      <div className={styles.mood}>{mood}</div>
    </div>
  );
}

export default StatusBox;
