import React, { useEffect, useState } from "react";
import { getAccessToken } from "../../utils/indexedDBUtils";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/images/Sign-up.png";
import Loader from "../../components/common/Loader";
import styles from "./Splash.module.css";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-toastify";

function Splash() {
  const navigate = useNavigate();
  const { user, fetchUserInfo } = useAuth();
  const [progressStep, setProgressStep] = useState("Initializing...");

  useEffect(() => {
    const checkUserInfo = async () => {
      const token = await getAccessToken();
      console.log(token);
      if (token) {
        try {
          setProgressStep("Fetching user information...");
          await new Promise((resolve) => setTimeout(resolve, 500));
          await fetchUserInfo();
          setProgressStep("Checking information...");
          await new Promise((resolve) => setTimeout(resolve, 500));

          if (user) {
            console.log(user.company_name);
            if (user.company_name) {
              setProgressStep("Redirecting to dashboard...");
              await new Promise((resolve) => setTimeout(resolve, 500));
              navigate("/dashboard");
            } else {
              setProgressStep("Redirecting to complete profile...");
              await new Promise((resolve) => setTimeout(resolve, 500));
              navigate("/auth/complete-info");
            }
          }
        } catch (error) {
          console.log(error);
          toast.error(`Error checking user info: ${error.message}`);
          navigate("/auth/login");
        }
      } else {
        setProgressStep("No token found, redirecting to login...");
        await new Promise((resolve) => setTimeout(resolve, 500));
        navigate("/auth/login");
      }
    };

    const delay = setTimeout(() => {
      checkUserInfo();
    }, 1500);

    return () => clearTimeout(delay);
  }, [fetchUserInfo, navigate, user]);

  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${bg})` }}>
      <div className={styles.container}>
        <Loader />
        <p className={`${styles.progressStep} ${styles.fadeIn}`}>
          {progressStep}
        </p>
      </div>
    </div>
  );
}

export default Splash;
