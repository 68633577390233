import React, { useRef } from 'react';
import styles from './Input.module.css';

function EmailInput({ value, onChange }) {
  const valueInputRef = useRef(null);

  const handleValueChange = (e) => {
    onChange(e);
    if (e.target.value.trim().length > 0) {
      valueInputRef.current.classList.add(styles.notEmpty);
    } else {
      valueInputRef.current.classList.remove(styles.notEmpty);
    }
  };

  return (
    <input
      ref={valueInputRef}
      className={styles.input}
      type="email"
      onChange={handleValueChange}
      inputMode="email"
      name="email"
      autoComplete="email"
      placeholder="email"
      value={value}
      autoFocus
    />
  );
}

export default EmailInput;
