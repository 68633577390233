import React, { useState } from "react";
import { useStructure } from "../../../contexts/StructureContext";
import styles from "./AddSectionForm.module.css";
import SaveButton from "../../common/buttons/SaveButton";
import { toast } from "react-toastify";
import { structureService } from "../../../services/structureService";

function AddSectionForm({ onClose }) {
  const [sectionName, setSectionName] = useState("");
  const [newSections, setNewSections] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const { departments, addSection, fetchData } = useStructure();

  const handleAdd = () => {
    if (sectionName.trim() && selectedDepartment) {
      const newSection = {
        department_id: parseInt(selectedDepartment, 10),
        sectionName: sectionName.trim(),
      };
      setNewSections((prev) => [...prev, newSection]);
      addSection(selectedDepartment, sectionName.trim());
      setSectionName("");
    } else {
      toast.error("Please select a department and enter a section name.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.warning("Sending request to submit sections");
    try {

      const response = await structureService.addSection(
        parseInt(selectedDepartment, 10),
        newSections.map((section) => section.sectionName)
      );
      console.log("Response:", response);

      if (response.message === "success") {
        onClose();
        fetchData();
        toast.success("Sections added successfully!");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Failed to submit sections: " + error.message);
    }
  };

  const deleteSection = (sectionName) => {
    setNewSections((prev) =>
      prev.filter((sec) => sec.sectionName !== sectionName)
    );
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <label htmlFor="department" className={styles.label}>
        Select Department
      </label>
      <select
        id="department"
        value={selectedDepartment}
        onChange={(e) => setSelectedDepartment(e.target.value)}
        className={styles.select}
      >
        <option value="">Select a department</option>
        {departments.map((department) => (
          <option key={department.id} value={department.id}>
            {department.name}
          </option>
        ))}
      </select>
      <label htmlFor="sectionName" className={styles.label}>
        Section Name
      </label>
      <div className={styles.row}>
        <input
          type="text"
          id="sectionName"
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          className={styles.input}
        />
        <div onClick={handleAdd} className={styles.submitButton}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
          >
            <path
              d="M17 1L6 12L1 7"
              stroke="#66BB6A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Add
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.sections}>
          {newSections.map((section, index) => (
            <div key={index} className={styles.section}>
              <span>{section.sectionName}</span>
              <div
                onClick={() => deleteSection(section.sectionName)}
                className={styles.deleteButton}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 4L4 12"
                    stroke="#365486"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 4L12 12"
                    stroke="#365486"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </div>
      <SaveButton type="submit">Save</SaveButton>
    </form>
  );
}

export default AddSectionForm;
