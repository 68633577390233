import React from "react";
import styles from "./ReportItem.module.css";
import { useNavigate } from "react-router-dom";
function ReportItem({ data }) {
  const navigate = useNavigate();
  const user = data.report.user;
  const text = data.report.rec_exercise;
  function handleClick() {
    navigate(`/report-detail/?employee_id=${data.report.id}`);
  }
  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <span className={styles.name}>{user?.[`first_name`]}</span>
      <span className={styles.date}>{data.created}</span>
      <span className={styles.report}>{text?.title}</span>
      <span className={styles.link}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M9.5 18L15.5 12L9.5 6"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </div>
  );
}

export default ReportItem;
