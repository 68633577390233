import React from "react";
import PropTypes from "prop-types";
import styles from "./SidebarLayout.module.css";

const SidebarLayout = ({ children, isOpen }) => {
  return (
    <div className={`${styles.container} ${isOpen ? styles.open : ""}`}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
};

export default SidebarLayout;
