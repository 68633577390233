import { post, get, put } from "../utils/api";

export const authService = {
  login: async (email, password, userType) => {
    const data = {
      email: email,
      password: password,
      user_type: userType,
    };
    const response = await post("account/login/", data);
    
    return response;
  },
  register: async (email, password) => {
    const response = await post("account/register/", { email, password });
    return response.data;
  },
  updateUserInfo: async (userData) => {
    const response = await put("account/info/", userData);
    return response.data;
  },
  forgotPassword: async (email) => {
    await post("auth/forgot-password", { email });
  },
  changePassword: async (data, resetToken) => {
    const customHeaders = {
      "X-Reset-Token": resetToken,
    };
    
    const res = await post("account/change-pass-token/", data, true, false, customHeaders);
    return res;
  },
  verifyOtp: async (email, otp_code) => {
    const res = await post("account/otp-verification/", { email, otp_code });
    return res;
  },
  sendOtp: async (email) => {
    const res = await post("account/send-otp/", { email });
    return res;
  },
  verifyToken: async (token) => {
    const response = await post("/auth/verify-token", { token });
    return response.data.user;
  },
  getUserInfo: async () => {
    const response = await get("account/info/");
    return response.data;
  },
  updateProfilePicture: async (imageFile) => {
    const formData = new FormData();
    formData.append("profile_pic_path", imageFile); 

    const response = await post("account/change-pic/", formData, true, true); 

    return response.data;
  },
};
