import React from "react";
import ReportDetailLayout from "../../components/layouts/ReportDetailLayout";
import { ReportProvider } from "../../contexts/ReportContext";
function ReportDetailPage() {
  return (
    <ReportProvider>
      <ReportDetailLayout />
    </ReportProvider>
  );
}

export default ReportDetailPage;
