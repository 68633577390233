import React from "react";
import styles from "./DashboardLayout.module.css";
import MentalHealthWidget from "../../dashboard/MentalHealthWidget";
import BurnoutWidget from "../../dashboard/BurnoutWidget";
import ReportWidget from "../../dashboard/ReportWidget";
import MoodTrackerWidget from "../../dashboard/MoodTrackerWidget";
import LeadScoreWidget from "../../dashboard/LeadScoreWidget";
import { useDashboard } from "../../../contexts/DashboardContext";
import { ScoreProvider } from "../../../contexts/ScoreContext";
function DashboardLayout() {
  const { data } = useDashboard();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftColumn}>
          <div className={styles.div3}>
            <MentalHealthWidget data={data?.overal_health} />
          </div>
          <div className={styles.div4}>
            <BurnoutWidget data={data?.burnout} />
          </div>
          <div className={styles.div5}>
            <ReportWidget data={data?.reports} />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.div1}>
            <MoodTrackerWidget data={data?.moods} />
          </div>
          <div className={styles.div2}>
            <ScoreProvider>
             <LeadScoreWidget  />
            </ScoreProvider>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
