import React from "react";
import styles from "./TotalMoodTrackerHeader.module.css";

function TotalMoodTrackerHeader({ MoodData }) {
  // Calculate totals for each mood
  const totals = MoodData?.reduce(
    (acc, current) => {
      acc.happy += current.happy;
      acc.neutral += current.neutral;
      acc.sad += current.sad;
      acc.overwhelmed += current.overwhelmed;
      return acc;
    },
    { happy: 0, neutral: 0, sad: 0, overwhelmed: 0 }
  );

  // Calculate the sum of all mood values
  const totalMoods =
    totals?.happy + totals?.neutral + totals?.sad + totals?.overwhelmed;

  // Calculate percentages for each mood
  const happyPercentage = ((totals?.happy / totalMoods) * 100).toFixed(0);
  const neutralPercentage = ((totals?.neutral / totalMoods) * 100).toFixed(0);
  const sadPercentage = ((totals?.sad / totalMoods) * 100).toFixed(0);
  const overwhelmedPercentage = (
    (totals?.overwhelmed / totalMoods) *
    100
  ).toFixed(0);

  return (
    <div>
      <div className={styles.labels}>
        <div>
          <span>
            {happyPercentage}% <span>Happy</span>
          </span>
        </div>
        <div>
          <span>
            {neutralPercentage}% <span>Natural</span>
          </span>
        </div>
        <div>
          <span>
            {overwhelmedPercentage}% <span>Overwhelmed</span>
          </span>
        </div>
        <div>
          <span>
            {sadPercentage}% <span>Sad</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default TotalMoodTrackerHeader;
