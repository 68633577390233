import React from 'react'
import styles from './LoginPage.module.css'
import AuthModal from '../../components/auth/AuthModal'
import LoginForm from '../../components/auth/LoginForm'
function Login() {
  return (
    <div className={styles.container}>
      <AuthModal children={<LoginForm/>} />
    </div>
  )
}

export default Login