import React, { useState } from "react";
import styles from "./LeadScore.module.css";
import SearchEmployee from "../../components/addEmployee/SearchEmployee";
import ScoreList from "../../components/leadScore/ScoreList";
import { ScoreProvider } from "../../contexts/ScoreContext";
function LeadScore() {
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  return (
    <ScoreProvider>
      <div className={styles.wrapper}>
        <div className={styles.body}>
          <SearchEmployee setFilteredEmployees={setFilteredEmployees} />
          <br />
          <ScoreList filteredEmployees={filteredEmployees} />
          <div className={styles.scrollbar} id="customScrollbar">
            <div className="scrollbar-thumb" id={styles.scrollbarThumb}></div>
          </div>
        </div>
      </div>
    </ScoreProvider>
  );
}

export default LeadScore;
