import React from 'react'
import styles from './IconButton.module.css'
function IconButton({icon, action, children}) {
  return (
    <div onClick={action} className={styles.container}>
        <img src={icon} alt="icon" />
        {children}
    </div>
  )
}

export default IconButton