import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { manageNotificationService } from "./services/manageNotificationService";
import Cookies from "js-cookie";
const firebaseConfig = {
  apiKey: "AIzaSyCMqpmPVsikngLdLZPWOmoMHBz4EEZqG7k",
  authDomain: "mondayblues-2dc40.firebaseapp.com",
  projectId: "mondayblues-2dc40",
  storageBucket: "mondayblues-2dc40.appspot.com",
  messagingSenderId: "38948406816",
  appId: "1:38948406816:web:6f0529aa351f9bf4cb5e45",
  measurementId: "G-VPDNE1PC4M",
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async (onMessageCallback) => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("Notification permission granted.");
      const currentToken = await getToken(messaging);
      console.log("FCM Token:", currentToken);

      const storedToken = Cookies.get("fcmToken");

      if (currentToken !== storedToken) {
        console.log("Sending token to server:", currentToken);
        await manageNotificationService.sendToken(currentToken);
        Cookies.set("fcmToken", currentToken, { expires: 365 });
      } else {
        console.log("Token already sent to server.");
      }
    } else {
      console.log("Notification permission denied.");
    }

    onMessage(messaging, (payload) => {
      console.log("Foreground Message:", payload);
      onMessageCallback(payload);
    });
  } catch (error) {
    console.error("Error setting up notifications:", error);
  }
};

export { messaging, setupNotifications };
