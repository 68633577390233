import React, { useState, useEffect } from "react";
import styles from "./SearchReport.module.css";
import { useReport } from "../../../contexts/ReportContext";
function SearchReport({ setFilteredReports }) {
  const { reports } = useReport();
  const [searchVal, setSearchVal] = useState("");

  const handleInput = (e) => {
    setSearchVal(e.target.value);
  };
  useEffect(() => {
    if (reports) {
      const filteredReports = reports.filter((reportData) => {
        const data = reportData.report.user;
        const { first_name, last_name, email } = data;
        const searchLower = searchVal?.toLowerCase();
        return (
          first_name?.toLowerCase().includes(searchLower) ||
          last_name?.toLowerCase().includes(searchLower) ||
          email?.toLowerCase().includes(searchLower)
        );
      });
      setFilteredReports(filteredReports);
    }
  }, [searchVal, reports, setFilteredReports]);

  return (
    <div className={styles.container}>
      <div className={styles.inputWrap}>
        <label htmlFor="Report-search" className={styles.inputLabel}></label>
        <input
          onChange={handleInput}
          value={searchVal}
          type="text"
          name="Report-search"
          id={styles.search}
          placeholder="Search Reports"
          className={styles.input}
        />
      </div>
    </div>
  );
}

export default SearchReport;
