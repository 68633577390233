import React, { useState } from "react";
import styles from "./NoteBox.module.css";
import { manageReportService } from "../../../../services/manageReportService";
import { toast } from "react-toastify";

const NoteBox = ({ employee }) => {
  const [note, setNote] = useState({ note: "" }); // Initialize note as an object
  const [error, setError] = useState("");
  const employee_id = employee?.id;

  const handleSave = () => {
    if (note.note.trim() === "") {
      setError("Note cannot be empty");
    } else {
      setError("");
      console.log("Note saved:", note);

      // Call the service with the correct note object
      manageReportService
        .sendNote(employee_id, note)
        .then(() => {
          toast.success("Note saved successfully");
        })
        .catch((err) => {
          toast.error("Failed to save note");
        });
    }
  };

  return (
    <div className={styles.noteContainer}>
      {/* <textarea
        className={`${styles.noteInput} ${error ? styles.error : ""}`}
        placeholder="Write a note"
        value={note.note}
        onChange={(e) => setNote({ note: e.target.value })} // Update note state correctly
      /> */}
      <div className={styles.paper}>
        <div className={styles[`paper-content`]}>
          <div className={styles.title}>Write a note</div>
          <textarea
            value={note.note}
            onChange={(e) => setNote({ note: e.target.value })}
            autofocus
          >
            Hello world !&#10;Here's a paper textarea tag.
          </textarea>
        </div>
      </div>
      <button className={styles.saveButton} onClick={handleSave}>
        Save
      </button>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

export default NoteBox;
