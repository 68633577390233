import React from "react";
import styles from "./BurnoutWidget.module.css";
import TotalBurnoutRate from "./TotalBurnoutRate";
function BurnoutWidget({ data }) {
  return (
    <div>
      <div className={styles.header}>
        <h2 className={styles.title}>Total Burnout Rate</h2>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
              stroke="#BDBDBD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <TotalBurnoutRate chartData={data} />
    </div>
  );
}

export default BurnoutWidget;
