import React, { useEffect, useState } from "react";
import styles from "./AddEmployee.module.css";
import AddEmployeeBtn from "../../components/addEmployee/AddEmployeeBtn";
import EmployeeList from "../../components/addEmployee/EmployeeList";
import SearchEmployee from "../../components/addEmployee/SearchEmployee";
import FilterBtn from "../../components/addEmployee/FilterBtn";
import AddEmploeyeModal from "../../components/addEmployee/AddEmploeyeModal";
import { useEmployee } from "../../contexts/EmployeeContext";
function AddEmployee() {
  const { fetchAllEmployees } = useEmployee();
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  useEffect(() => {
    fetchAllEmployees();
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.left}>
          <AddEmployeeBtn />
          <SearchEmployee setFilteredEmployees={setFilteredEmployees} />
        </div>
        <div className={styles.right}>
          <FilterBtn setFilteredEmployees={setFilteredEmployees} />
        </div>
      </div>
      <div className={styles.body}>
        <EmployeeList filteredEmployees={filteredEmployees} />
        <AddEmploeyeModal />
        <div className={styles.scrollbar} id="customScrollbar">
          <div className="scrollbar-thumb" id={styles.scrollbarThumb}></div>
        </div>
      </div>
    </div>
  );
}

export default AddEmployee;
