import React from 'react'
import url from '../../../assets/images/logo.png'
function Logo() {
  return (
    <div>
        <img src={url} alt='Logo' />
    </div>
  )
}

export default Logo