import React from "react";
import EmployeeItem from "../EmployeeItem";
import styles from "./EmployeeList.module.css";
import { useEmployee } from "../../../contexts/EmployeeContext";
import EmptyList from "./EmptyList";
import Loader from "../../common/Loader";

function EmployeeList({ filteredEmployees }) {
  const { loading } = useEmployee();

  if (loading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.header}>
            <th className={styles.name}>Name</th>
            <th className={styles.email}>Email</th>
            <th className={styles.phone}>Phone</th>
            <th className={styles.department}>Department</th>
            <th className={styles.section}>Section</th>
            <th className={styles.team}>Team</th>
            <th className={styles.jobTitle}>Job Title</th>
            <th className={styles.employeeId}>Employee ID</th>
          </tr>
        </thead>
        <tbody className={styles.list}>
          {filteredEmployees?.length > 0 ? (
            filteredEmployees.map((employee) => {
              const department =
                employee.team?.section?.department?.title || "N/A";
              const section = employee.team?.section?.title || "N/A";
              const team = employee.team?.title || "N/A";

              return (
                <EmployeeItem
                  key={employee.id}
                  department={department}
                  email={employee.email}
                  employeeId={employee.id}
                  jobTitle={employee.job_title}
                  name={`${employee.first_name} ${employee.last_name}`}
                  phone={employee.phone}
                  section={section}
                  team={team}
                />
              );
            })
          ) : (
            <div>
              <EmptyList />
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default EmployeeList;
