import React from "react";
import {
  BrowserRouter as Router,
  Routes as RoutesWrapper,
  Route,
} from "react-router-dom";
import NotFound from "../pages/NotFound";
import Dashboard from "../pages/DashboardPage";
import ROUTE_CONSTANTS from "../constants/ROUTE_CONSTANTS";
import GeneralLayout from "../components/layouts/GeneralLayout";
import { AuthProvider } from "../contexts/AuthContext";
import Profile from "../pages/ProfilePage";
import AuthLayout from "../components/layouts/AuthLayout";
import Login from "../pages/LoginPage";
import Register from "../pages/RegisterPage";
import AddEmployee from "../pages/AddEmployee";
import Splash from "../pages/Splash";
import ReportPage from "../pages/ReportPage";
import LeadScore from "../pages/LeadScore";
import SetupStructure from "../pages/SetupStructure";
import { StructureProvider } from "../contexts/StructureContext";
import { ToastContainer } from "react-toastify";
import CompleteInfo from "../pages/CompleteInfo";
import ReportDetailPage from "../pages/ReportDetailPage";
import NotificationPage from "../pages/NotificationPage";
import ChangePaassword from "../pages/ChangePassword";
import ForgotPassword from "../pages/ForgotPassword";
import OtpVerification from "../pages/OtpVerification";
function Routes() {
  return (
    <Router basename={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
      <AuthProvider>
        <StructureProvider>
          <RoutesWrapper>
            <Route
              path={ROUTE_CONSTANTS.AUTH.ABSOLUTE}
              element={<AuthLayout />}
            >
              <Route
                path={ROUTE_CONSTANTS.LOGIN.RELATIVE}
                element={<Login />}
              />
              <Route
                path={ROUTE_CONSTANTS.COMPLETEINFO.RELATIVE}
                element={<CompleteInfo />}
              />
              <Route
                path={ROUTE_CONSTANTS.REGISTER.RELATIVE}
                element={<Register />}
              />
              <Route 
              path={ROUTE_CONSTANTS.FORGOTPASSWORD.RELATIVE}
              element={<ForgotPassword />}
              />
              <Route
                path={ROUTE_CONSTANTS.CHANGEPASSWORD.RELATIVE}
                element={<ChangePaassword />}
              />
              <Route 
              path={ROUTE_CONSTANTS.OTPVERIFICATION.RELATIVE}
              element={<OtpVerification />}
              />
            </Route>
            <Route path="/" element={<Splash />} />
            <Route
              path={ROUTE_CONSTANTS.ROOT.ABSOLUTE}
              element={<GeneralLayout />}
            >
              <Route
                path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE}
                element={<NotFound />}
              />
              <Route
                path={ROUTE_CONSTANTS.NOTIFICATION.ABSOLUTE}
                element={<NotificationPage />}
              />
              <Route
                path={ROUTE_CONSTANTS.ADDEMPROYEE.ABSOLUTE}
                element={<AddEmployee />}
              />
              <Route
                path={ROUTE_CONSTANTS.REPORT.ABSOLUTE}
                element={<ReportPage />}
              />
              <Route
                path={ROUTE_CONSTANTS.REPORTDETAIL.ABSOLUTE}
                element={<ReportDetailPage />}
              />
              <Route
                path={ROUTE_CONSTANTS.SETUPSTRUCTURE.ABSOLUTE}
                element={<SetupStructure />}
              />
              <Route
                path={ROUTE_CONSTANTS.LEADSCORE.ABSOLUTE}
                element={<LeadScore />}
              />

              <Route path="/:userData/*" element={<Profile />} />
              <Route
                path={ROUTE_CONSTANTS.DASHBOARD.ABSOLUTE}
                element={<Dashboard />}
              />
            </Route>
          </RoutesWrapper>
        </StructureProvider>
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
}

export default Routes;
