import { get, post, put, del } from '../utils/api';

export const manageEmployeeService = {
  fetchEmployee: async (employeeId) => {
    return await get(`management/employees/${employeeId}`, true);
  },
  
  fetchAllEmployees: async () => {
    return await get('management/employees/', true);
  },
  
  addEmployee: async (employeeData) => {
    return await post('account/employee-info/', employeeData, true);
  },
  
  editEmployee: async (employeeId, updatedData) => {
    return await put(`account/employee-info/${employeeId}/`, updatedData, true);
  },
  
  deleteEmployee: async (employeeId) => {
    return await del(`account/employee-info/${employeeId}/`, true);
  }
};
