import React from 'react'
import styles from './ScoreLeaders.module.css'
function ScoreLeaders({ leaders}) {
  console.log(leaders)
  return (
    <div className={styles.wrapper}>
       {leaders?.map((leader, index) => ( 
        <div key={index} className={styles.leader}>
          <div className={styles.leaderName}>{leader.first_name}</div>
          <div>
          <div className={styles.leaderScore}>{leader.exp_value}</div>
          XP
          </div>
        </div> 
        ))}
        {!leaders?.length && <div className={styles.noLeader}>No Leaders found</div>}
    </div>
  )
}

export default ScoreLeaders