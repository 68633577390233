import React from 'react'

function index() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M15 3.5H19C19.5304 3.5 20.0391 3.71071 20.4142 4.08579C20.7893 4.46086 21 4.96957 21 5.5V19.5C21 20.0304 20.7893 20.5391 20.4142 20.9142C20.0391 21.2893 19.5304 21.5 19 21.5H15" stroke="#B00020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 17.5L15 12.5L10 7.5" stroke="#B00020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 12.5H3" stroke="#B00020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default index