import { openDB } from "idb";

const DB_NAME = "AuthoirzationDB";
const STORE_NAME = "auth";
const TOKEN_KEY = "token";

async function initDB() {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
}

export async function setAccessToken(token) {
  const db = await initDB();
  await db.put(STORE_NAME, token, TOKEN_KEY);
}

export async function getAccessToken() {
  const db = await initDB();
  return db.get(STORE_NAME, TOKEN_KEY);
}

export async function clearAccessToken() {
  console.log("delete");
  const db = await initDB();
  await db.delete(STORE_NAME, TOKEN_KEY);
}
