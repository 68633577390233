import React from 'react'
import styles from './Register.module.css'
import AuthModal from '../../components/auth/AuthModal'
import RegisterForm from '../../components/auth/RegisterForm'
function Register() {
  return (
    <div className={styles.container}>
      <AuthModal children={<RegisterForm/>} />
  </div>
  )
}

export default Register