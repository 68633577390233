import React, { useState } from 'react';
import styles from './ImagePicker.module.css';
import placeholder from '../../../assets/images/image-picker-placeholder.png';
import { useAuth } from '../../../contexts/AuthContext';
import { authService } from '../../../services/authService';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const ImagePicker = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const { user, setUser } = useAuth(); // Assuming setUser is available to update the user's profile image
  const pic_path = user?.profile_pic_path;

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    // Validate file type
    if (file && !file.type.startsWith('image/')) {
      setErrorMessage('Please select a valid image file.');
      setProfileImage(null);
      return;
    }

    // Validate file size
    if (file && file.size > MAX_FILE_SIZE) {
      setErrorMessage('File size exceeds 5MB. Please select a smaller file.');
      setProfileImage(null);
      return;
    }

    // Clear previous errors if valid image
    if (file) {
      setProfileImage(URL.createObjectURL(file));
      setErrorMessage('');

      // Auto-save the image
      try {
        setIsUploading(true);
        const response = await authService.updateProfilePicture(file);
        console.log('Upload response:', response);

        // Check if response contains profile_pic_path
        if (response.profile_pic_path) {
          console.log('Updating user profile pic...');
          setUser((prevUser) => ({
            ...prevUser,
            profile_pic_path: response.profile_pic_path,
          }));
          console.log('Updated user:', user);
        } else {
          setErrorMessage('Failed to upload the image. Please try again.');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        setErrorMessage('An error occurred while uploading the image.');
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div className={styles.profileImage}>
      <label htmlFor="profileImageUpload" aria-label="Upload profile image">
        {profileImage ? (
          <img src={profileImage} alt="Selected profile" className={styles.imagePreview} />
        ) : pic_path ? (
          <img src={pic_path} alt="Profile" className={styles.imagePreview} />
        ) : (
          <img src={placeholder} alt="Placeholder" className={styles.imagePreview} />
        )}
      </label>
      <input
        id="profileImageUpload"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        className={styles.imageInput}
        aria-describedby="imageUploadError"
      />
      {errorMessage && <p id="imageUploadError" className={styles.error}>{errorMessage}</p>}
      {isUploading && <p className={styles.uploading}>Uploading...</p>}
      <span className={styles.title}>Company Logo</span>
    </div>
  );
};

export default ImagePicker;