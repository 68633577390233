const ROUTE_CONSTANTS = {
  ROOT: {
    RELATIVE: "/",
    ABSOLUTE: "/",
  },
  ROOT_STAR: {
    RELATIVE: "/*",
    ABSOLUTE: "/*",
  },
  AUTH: {
    RELATIVE: "auth",
    ABSOLUTE: "/auth",
  },
  LOGIN: {
    RELATIVE: "login",
    ABSOLUTE: "/login",
  },
  CHANGEPASSWORD: {
    RELATIVE: "change-password",
    ABSOLUTE: "/change-password",
  },
  NOTIFICATION: {
    RELATIVE: "notification",
    ABSOLUTE: "/notification",
  },
  COMPLETEINFO: {
    RELATIVE: "complete-info",
    ABSOLUTE: "/complete-info",
  },
  DASHBOARD: {
    RELATIVE: "dashboard",
    ABSOLUTE: "/dashboard",
  },
  LEADSCORE: {
    RELATIVE: "lead-scoring",
    ABSOLUTE: "/lead-scoring",
  },
  SETUPSTRUCTURE: {
    RELATIVE: "setup-structure",
    ABSOLUTE: "/setup-structure",
  },
  SPLASH: {
    RELATIVE: "",
    ABSOLUTE: "/",
  },
  OTPVERIFICATION: {
    RELATIVE: "otp-verification",
    ABSOLUTE: "/otp-verification",
  },
  REGISTER: {
    RELATIVE: "register",
    ABSOLUTE: "/register",
  },
  ADDEMPROYEE: {
    RELATIVE: "addemployee",
    ABSOLUTE: "/addemployee",
  },
  FORGOTPASSWORD: {
    RELATIVE: "forgot-password",
    ABSOLUTE: "/forgot-password",
  },
  REPORT: {
    RELATIVE: "reports",
    ABSOLUTE: "/reports",
  },
  REPORTDETAIL: {
    RELATIVE: "report-detail",
    ABSOLUTE: "/report-detail",
  },
  USER: {
    RELATIVE: "user",
    ABSOLUTE: "/user",
    PROFILE: {
      RELATIVE: (firstName, lastName) => `${firstName}-${lastName}/profile/`,
      ABSOLUTE: (firstName, lastName) => `/${firstName}-${lastName}/profile/`,
    },
    GET_BY_DATA: (userData) =>
      `${userData.first_name}-${userData.last_name}/profile/`,
  },
};

export default ROUTE_CONSTANTS;
