import React from "react";
import styles from "./ScoreList.module.css";
import LeadScoreItem from "../LeadScoreItem";
import Loader from "../../common/Loader";
import { useScore } from "../../../contexts/ScoreContext";
import EmptyList from "../EmptyList";
function ScoreList({ filteredEmployees }) {
  const { loading, scores } = useScore();
  console.log(scores);
  if (loading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {filteredEmployees?.length > 0 ? (
          filteredEmployees?.map((data) => {
            return <LeadScoreItem key={data.id} data={data} />;
          })
        ) : (
          <div>
            <EmptyList />
          </div>
        )}
      </div>
    </div>
  );
}

export default ScoreList;
