import React, { useState, useEffect } from 'react';
import styles from './AuthModal.module.css';
import WelcomeMsg from './WelcomeMsg';
import Loader from '../../common/Loader'

const Modal = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            setLoading(false);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    {loading ? ( 
                        <Loader />
                    ) : (
                        <>
                            {!isMobile && <WelcomeMsg />}
                            <div className={styles.form}>{children}</div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Modal;
