import React, { useState, useEffect } from "react";
import styles from "./SearchEmployee.module.css";
import { useEmployee } from "../../../contexts/EmployeeContext";

function SearchEmployee({ setFilteredEmployees }) {
  const { employees } = useEmployee();
  const [searchVal, setSearchVal] = useState("");

  const handleInput = (e) => {
    setSearchVal(e.target.value);
  };

  useEffect(() => {
    if (employees) {
      const filteredEmployees = employees.filter((employee) => {
        const { first_name, last_name, email } = employee;
        const searchLower = searchVal.toLowerCase();
        return (
          first_name.toLowerCase().includes(searchLower) ||
          last_name.toLowerCase().includes(searchLower) ||
          email.toLowerCase().includes(searchLower)
        );
      });
      setFilteredEmployees(filteredEmployees);
    }
  }, [searchVal, employees, setFilteredEmployees]);

  return (
    <div className={styles.container}>
      <div className={styles.inputWrap}>
        <label htmlFor="Employee-search" className={styles.inputLabel}></label>
        <input
          onChange={handleInput}
          value={searchVal}
          type="text"
          name="Employee-search"
          id={styles.search}
          placeholder="Search Employee"
          className={styles.input}
        />
      </div>
    </div>
  );
}

export default SearchEmployee;
