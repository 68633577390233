import React from 'react'
import styles from './WelcomeMsg.module.css'
import url from '../../../../assets/images/welcome.png'
function WelcomeMsg() {
    return (
        <div className={styles.container}>
                <img src={url} alt="Welcome" />
                <h1>Welcome!</h1>
                <p className={styles.p}>
                Ready to make today a great day for your team? Let's create a healthier workplace together.
                </p>
        </div>
    )
}

export default WelcomeMsg