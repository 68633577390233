import React from "react";
import styles from "./SymptomBox.module.css";
function SymptomBox({ employee }) {
  const symptoms = employee?.symptoms;

  return (
    <div className={styles.container}>
      {symptoms?.map((symptom) => {
        return (
          <div key={symptom} className={styles.symptom}>
            {symptom}
          </div>
        );
      })}
    </div>
  );
}

export default SymptomBox;
