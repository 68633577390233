import React, { useState } from "react";
import { useStructure } from "../../../contexts/StructureContext";
import styles from "./AddDepartmentForm.module.css";
import SaveButton from "../../common/buttons/SaveButton";
import { toast } from "react-toastify";
import { structureService } from "../../../services/structureService";

function AddDepartmentForm({ onClose }) {
  const [departmentName, setDepartmentName] = useState("");
  const [newDepartments, setNewDepartments] = useState([]);

  const { departments, addDepartment, fetchData } = useStructure();
  const submitDepartments = async () => {
    try {
      if (departments.length > 0) {
        const res =  await structureService.addDepartment(newDepartments);

      toast.info(res.message)
      }
    } catch (error) {
      throw new Error("Failed to submit departments: " + error.message);
    }
  };
  const handleAdd = () => {
    if (departmentName.trim()) {
    
      
      setNewDepartments((prev) => [...prev, departmentName.trim()]);
      addDepartment(departmentName.trim());
      setDepartmentName("");
    } else {
      toast.error("Please enter a department name.");
    }
  };

  const handleDelete = (id) => {
    setNewDepartments((prev) => prev.filter((dep) => dep.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitDepartments([...departments, ...newDepartments]);
      setNewDepartments([]);
      setDepartmentName("");
      fetchData();
      onClose();
    } catch(e) {
      toast.error(e);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <label htmlFor="departmentName" className={styles.label}>
        Department Name
      </label>
      <div className={styles.row}>
        <input
          type="text"
          id="departmentName"
          value={departmentName}
          onChange={(e) => setDepartmentName(e.target.value)}
          className={styles.input}
        />
        <div className={styles.buttons}>
          <div onClick={handleAdd} className={styles.submitButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="13"
              viewBox="0 0 18 13"
              fill="none"
            >
              <path
                d="M17 1L6 12L1 7"
                stroke="#66BB6A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Add
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.departments}>
          {newDepartments &&
            newDepartments.map((department) => (
              <div className={styles.department}>
                <span>{department}</span>
                <div
                  onClick={() => handleDelete(department.id)}
                  className={styles.deleteIcon}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M12 4L4 12"
                      stroke="#365486"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4 4L12 12"
                      stroke="#365486"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            ))}
        </div>
      </div>
      <SaveButton onClick={handleSubmit}>Save</SaveButton>
    </form>
  );
}

export default AddDepartmentForm;
