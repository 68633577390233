import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import {
  setAccessToken,
  getAccessToken,
  clearAccessToken,
} from "../utils/indexedDBUtils";
import { authService,  } from "../services/authService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [resetToken, setResetToken] = useState(null);
  const login = async (email, password) => {
    const userType = "admin";
    try {
      const data = await authService.login(email, password, userType);
      if(data.data){
        setUser(data.data.user);
        await setAccessToken(data.data.access_token);
        toast.success("Login successful!");
      } else {
        toast.error(data.message);
      }

      
    } catch (error) {
      toast.error(error);
      throw error;
    }
  };
  const extractErrorMessage = (error) => {
    if (error.response && error.response.data) {
      const { message } = error.response.data;
      return message || "An unexpected error occurred.";
    }
    if (error.message) {
      return error.message;
    }
    return "An unexpected error occurred. Please try again.";
  };
  const updateUserInfo = async (userData) => {
    try {
      const updatedData = await authService.updateUserInfo(userData);
      setUser(updatedData.data);
      toast.success("User information updated successfully!");
    } catch (error) {
      toast.error(error);
      throw error;
    }
  };

  const logout = async () => {
    setUser(null);
    const res = await clearAccessToken();
    toast.success(res);
  };

  const register = async (email, password) => {
    try {
      const data = await authService.register(email, password);
      setUser(data.user);
      await setAccessToken(data.access_token);

      const token = await getAccessToken();
      console.log(token);
      if (token != null) {
        toast.success("Register successful!");
      }
      return data;
    } catch (error) {
      throw error;
    }
  };
  const verifyOtp = useCallback(async (email, otp_code) => {
    setLoading(true);
    try {
      const response = await authService.verifyOtp(email, otp_code);
      setResetToken(response.data.reset_password_token);
      return response;
    } catch (err) {
      console.error("Failed to verify OTP:", err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const sendOtp = useCallback(async (email) => {
    setLoading(true);
    try {
      const response = await authService.sendOtp(email);
      return response.message;
    } catch (err) {
      toast.error(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, []);
  const forgotPassword = async (email) => {
    try {
      await authService.forgotPassword(email);
    } catch (error) {
      toast.error("Forgot password failed:", error);
      throw error;
    }
  };

 

  const fetchUserInfo = async () => {
    try {
      const data = await authService.getUserInfo();
      setUser(data);
    } catch (error) {
      console.error("Fetching user info failed:", error);
    }
  };
  const initializeToken = async () => {
    const token = await getAccessToken();
    if (token != null) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    if (token) {
      setAccessToken(token);
      await fetchUserInfo();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    
    initializeToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loading,
        login,
        logout,
        register,
        updateUserInfo,
        forgotPassword,
        isAuthenticated,
        resetToken,
        verifyOtp,
        sendOtp,
        fetchUserInfo,
        initializeToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
