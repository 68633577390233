import React from "react";
import styles from "./SetupStructure.module.css";
import AddNewBtn from "../../components/setupStructure/AddNewBtn";
import Modal from "../../components/common/Modal";
import { useStructure } from "../../contexts/StructureContext";
import AddDepartmentForm from "../../components/setupStructure/AddDepartmentForm";
import AddSectionForm from "../../components/setupStructure/AddSectionForm";
import AddTeamForm from "../../components/setupStructure/AddTeamForm";
import StructureList from "../../components/setupStructure/StructureList";

function SetupStructure() {
  const { showSelectedType, setShowSelectedType, addType,fetchData } = useStructure();

  const handleCloseModal = () => {
    fetchData();
    setShowSelectedType(false);

  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.left}>
          <AddNewBtn />
        </div>
        <div className={styles.right}>{/* going to be search bar here */}</div>
      </div>
      <div className={styles.body}>
        <StructureList />
        {showSelectedType && (
          <Modal onClose={handleCloseModal}>
            {addType === "Department" && (
              <AddDepartmentForm onClose={handleCloseModal} />
            )}
            {addType === "Section" && (
              <AddSectionForm onClose={handleCloseModal} />
            )}
            {addType === "Team" && <AddTeamForm onClose={handleCloseModal} />}
          </Modal>
        )}

        <div className={styles.scrollbar} id="customScrollbar">
          <div className={styles.scrollbarThumb} id="scrollbarThumb"></div>
        </div>
      </div>
    </div>
  );
}

export default SetupStructure;
