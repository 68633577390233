import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./ForgotPassword.module.css";
import EmailInput from "./EmailInput";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

function ForgotPassword() {
  const { sendOtp, logout } = useAuth();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);

  const sendCode = useCallback(async (data) => {
    try {
      setLoading(true); 

      if (!data.email) {
        toast.error("Email is required");
        setLoading(false); 
        return;
      }

      const response = await sendOtp(data.email);
      toast.info(response);

      if (response !== undefined && response !== "There is no user with this email in the database") {
        navigate("/auth/otp-verification", { state: { email: data.email } });
      }
    } catch (err) {
      toast.error("Failed to send verify code: " + err.message);
      console.error("Failed to send verify code:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    logout();
    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        handleSubmit(sendCode)();
      }
    };

    window.addEventListener("keydown", handleEnterKey);

    return () => {
      window.removeEventListener("keydown", handleEnterKey);
    };
  }, [handleSubmit, sendCode]);

  return (
    <div className={styles.container}>
      <h2>You Forgot Password!</h2>
      <p>Please enter your email address to reset your password</p>
      <div className={styles.form}>
        <EmailInput
          type="email"
          label="Email Address"
          placeholder="Please enter your email address"
          error={errors.email?.message}
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Please enter a valid email address",
            },
          })}
        />
        <button
          className={styles.submitButton}
          onClick={handleSubmit(sendCode)}
          disabled={loading} // Disable button while loading
        >
          {loading ? "Sending..." : "Next"} {/* Change text based on loading */}
        </button>
        <Link to="/auth/login" className={`${styles.skip} center--all pad--s`}>
          Back
        </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;