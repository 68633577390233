import React from "react";
import styles from "./EmptyList.module.css";
import emptyUrl from "../../../assets/images/empty.png";
function EmptyList() {
  return (
    <div className={styles.container}>
      <img className={styles.img} src={emptyUrl} alt="empty" />
      <div className={styles.title}>No Score Here</div>
    </div>
  );
}

export default EmptyList;
