import React from 'react';
import styles from './AuthLayout.module.css';
import { Outlet, useLocation } from 'react-router-dom';
import AuthBackground from '../../auth/AuthBackground';
function AuthLayout() {
    const location = useLocation();

    return (
        <div className={styles.wrapper} >
            <AuthBackground route={location.pathname} />
            <div className={styles.container}>
                <Outlet />
            </div>
        </div>
    );
}

export default AuthLayout;
