import React from "react";
import styles from "./LeadScoreItem.module.css";

function index({ data }) {
  return (
    <div className={styles.wrapper}>
      <span className={styles.name}>{data && data[`first_name`]}</span>
      <span className={styles.score}>
        <span className={styles.report}>{data && data.exp_value}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
        >
          <g filter="url(#filter0_ii_257_1803)">
            <path
              d="M6.09478 0.928375C6.45471 0.16162 7.54529 0.16162 7.90522 0.928375L9.24551 3.78355C9.38708 4.08512 9.66953 4.2964 9.99882 4.34701L13.0777 4.82022C13.882 4.94383 14.2098 5.92502 13.6413 6.50722L11.3527 8.85114C11.1309 9.07833 11.0302 9.39736 11.0813 9.71073L11.6124 12.9672C11.7468 13.7911 10.8721 14.4073 10.1416 14.0033L7.48392 12.5336C7.18279 12.3671 6.8172 12.3671 6.51608 12.5336L3.85845 14.0033C3.12788 14.4073 2.25318 13.7911 2.38757 12.9672L2.91872 9.71073C2.96983 9.39736 2.86909 9.07833 2.64727 8.85114L0.358695 6.50722C-0.209757 5.92502 0.118038 4.94383 0.922286 4.82022L4.00118 4.34701C4.33047 4.2964 4.61292 4.08512 4.75449 3.78355L6.09478 0.928375Z"
              fill="#FFCE00"
            />
          </g>
          <defs>
            <filter
              id="filter0_ii_257_1803"
              x="-0.926758"
              y="0.353516"
              width="16.8535"
              height="15.7769"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-1" dy="2" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_257_1803"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="2" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.954167 0 0 0 0 0.770817 0 0 0 0 0 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_innerShadow_257_1803"
                result="effect2_innerShadow_257_1803"
              />
            </filter>
          </defs>
        </svg>
      </span>
    </div>
  );
}

export default index;
