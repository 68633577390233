import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { structureService } from "../services/structureService";
import { toast } from "react-toastify";

const StructureContext = createContext();

export const StructureProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [newlyAddedDepartments, setNewlyAddedDepartments] = useState([]);
  const [showSelectedType, setShowSelectedType] = useState(false);
  const [showSelectTypeModal, setShowSelectTypeModal] = useState(false);
  const [addType, setAddType] = useState("");
  const [didFormChange, setDidFormChange] = useState(false);
  const [newlyAddedSections, setNewlyAddedSections] = useState([]);
  const [newlyAddedTeams, setNewlyAddedTeams] = useState([]);

  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const response = await structureService.getDepartments();
      if (response && Array.isArray(response)) {
        const parsedDepartments = response.map((apiDepartment) => ({
          id: apiDepartment.id,
          name: apiDepartment.title,
          sections: [],
        }));
        setDepartments(parsedDepartments);
        fetchSections();
      } else {
        console.error("Failed to fetch departments: Invalid response");
      }
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    } finally {
    }
  };
  const fetchTeams = useCallback(async () => {
    try {
      const fetchedTeams = await structureService.getTeams();
  
      // Check if fetchedTeams is an array
      if (!Array.isArray(fetchedTeams)) {
        throw new Error("Fetched teams is not an array");
      }
  
      setDepartments((prevDepartments) => {
        return prevDepartments.map((department) => {
          const updatedSections = department.sections.map((section) => {
            const teamsForSection = fetchedTeams.filter(
              (team) => team.section.id === section.id
            );
            return { ...section, teams: teamsForSection };
          });
          return { ...department, sections: updatedSections };
        });
      });
    } catch (error) {
      toast.error("Failed to fetch teams.");
      console.error("Error fetching teams:", error); // Log the error for debugging
    } finally {
      setLoading(false);
    }
  }, []);
  
  const fetchSections = useCallback(async () => {
    try {
      const fetchedSections = await structureService.getSections();

      setDepartments((prevDepartments) => {
        if (!prevDepartments) {
          return [];
        }
        return prevDepartments.map((department) => {
          const updatedDepartment = { ...department };
          const matchingSections = fetchedSections.filter(
            (section) => section.department.id === department.id
          );
          updatedDepartment.sections = matchingSections;
          return updatedDepartment;
        });
      });
      fetchTeams();
    } catch (error) {
      toast.error("Failed to fetch sections.");
    }
  }, [fetchTeams]);

  

  const addDepartment = useCallback((name) => {
    setDepartments((prevDepartments) => [
      ...prevDepartments,
      { id: Date.now(), name, sections: [] },
    ]);
    setNewlyAddedDepartments((prev) => [...prev, name]);
  }, []);

  const editDepartment = useCallback((departmentId, newName) => {
    setDepartments((prevDepartments) =>
      prevDepartments.map((department) =>
        department.id === parseInt(departmentId)
          ? { ...department, title: newName }
          : department
      )
    );
  }, []);

  const deleteDepartment = useCallback((departmentId) => {
    setDepartments((prevDepartments) =>
      prevDepartments.filter(
        (department) => department.id !== parseInt(departmentId)
      )
    );
  }, []);

  const addSection = useCallback((departmentId, title) => {
    const parsedDepartmentId = parseInt(departmentId, 10);
    if (isNaN(parsedDepartmentId)) {
      toast.error("Invalid Department ID.");
      return;
    }

    setDepartments((prevDepartments) => {
      return prevDepartments.map((department) => {
        if (department.id === parsedDepartmentId) {
          const updatedSections = [
            ...department.sections,
            { id: Date.now(), title, teams: [] },
          ];
          return { ...department, sections: updatedSections };
        }
        return department;
      });
    });

    setNewlyAddedSections((prev) => [
      ...prev,
      { departmentId: parsedDepartmentId, title },
    ]);
  }, []);

  const editSection = useCallback((sectionId, newName) => {
    const parsedSectionId = parseInt(sectionId, 10);
    if (isNaN(parsedSectionId)) {
      toast.error("Invalid Section ID.");
      return;
    }

    setDepartments((prevDepartments) => {
      return prevDepartments.map((department) => {
        const updatedSections = department.sections.map((section) =>
          section.id === parsedSectionId
            ? { ...section, title: newName }
            : section
        );
        return { ...department, sections: updatedSections };
      });
    });
  }, []);

  const deleteSection = useCallback((sectionId) => {
    const parsedSectionId = parseInt(sectionId, 10);
    if (isNaN(parsedSectionId)) {
      toast.error("Invalid Section ID.");
      return;
    }

    setDepartments((prevDepartments) => {
      return prevDepartments.map((department) => {
        const updatedSections = department.sections.filter(
          (section) => section.id !== parsedSectionId
        );
        return { ...department, sections: updatedSections };
      });
    });
  }, []);

  const addTeam = useCallback((sectionId, title) => {
    const parsedSectionId = parseInt(sectionId, 10);
    if (isNaN(parsedSectionId)) {
      toast.error("Invalid Section ID.");
      return;
    }
    setDepartments((prevDepartments) => {
      return prevDepartments.map((department) => {
        const updatedSections = department.sections.map((section) => {
          if (section.id === parsedSectionId) {
            const updatedTeams = [
              ...section.teams,
              { id: Date.now(), title: title },
            ];
            return { ...section, teams: updatedTeams };
          }
          return section;
        });
        return { ...department, sections: updatedSections };
      });
    });

    setNewlyAddedTeams((prev) => [
      ...prev,
      { sectionId: parsedSectionId, title },
    ]);
  }, []);

  const editTeam = useCallback((teamId, newName) => {
    const parsedTeamId = parseInt(teamId, 10);
    if (isNaN(parsedTeamId)) {
      toast.error("Invalid Team ID.");
      return;
    }

    setDepartments((prevDepartments) => {
      return prevDepartments.map((department) => {
        const updatedSections = department.sections.map((section) => {
          const updatedTeams = section.teams.map((team) =>
            team.id === parsedTeamId ? { ...team, title: newName } : team
          );
          return { ...section, teams: updatedTeams };
        });
        return { ...department, sections: updatedSections };
      });
    });
  }, []);

  const deleteTeam = useCallback((teamId) => {
    const parsedTeamId = parseInt(teamId, 10);
    if (isNaN(parsedTeamId)) {
      toast.error("Invalid Team ID.");
      return;
    }

    setDepartments((prevDepartments) => {
      return prevDepartments.map((department) => {
        const updatedSections = department.sections.map((section) => {
          const updatedTeams = section.teams.filter(
            (team) => team.id !== parsedTeamId
          );
          return { ...section, teams: updatedTeams };
        });
        return { ...department, sections: updatedSections };
      });
    });
  }, []);

  const openAddTypeModal = (type) => {
    setShowSelectTypeModal(true);
    setAddType(type);
  };
  const fetchData = async () => {
    await fetchDepartments();
  };
  useEffect(() => {
    setLoading(true);
    fetchDepartments();
  }, []);

  return (
    <StructureContext.Provider
      value={{
        fetchData,
        departments,
        addDepartment,
        editDepartment,
        deleteDepartment,
        addSection,
        editSection,
        deleteSection,
        addTeam,
        editTeam,
        deleteTeam,
        newlyAddedDepartments,
        newlyAddedSections,
        newlyAddedTeams,
        setShowSelectedType,
        showSelectedType,
        setShowSelectTypeModal,
        showSelectTypeModal,
        setAddType,
        addType,
        didFormChange,
        setDidFormChange,
        openAddTypeModal,
        fetchDepartments,
        fetchSections,
        fetchTeams,
        loading,
      }}
    >
      {children}
    </StructureContext.Provider>
  );
};

export const useStructure = () => useContext(StructureContext);
