import React from 'react';
import propTypes from 'prop-types';
import styles from './SaveButton.module.css';

function SaveButton({ onClick, children, ...props }) {
  return (
    <button className={styles.saveButton} onClick={onClick} {...props}>
      {children}
    </button>
  );
}

SaveButton.propTypes = {
  onClick: propTypes.func.isRequired,
  children: propTypes.node.isRequired,
};

export default SaveButton;
