import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

// Custom plugin for adding box shadow
const shadowPlugin = {
  id: "shadowPlugin",
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    const _stroke = ctx.stroke;
    ctx.stroke = function () {
      ctx.save();
      ctx.shadowColor = "rgba(127, 199, 217, 0.5)";
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 4;
      _stroke.apply(this, arguments);
      ctx.restore();
    };
  },
};

// Register the plugin
ChartJS.register(shadowPlugin);

const TotalBurnoutRate = ({ chartData }) => {
  // Map the chartData to labels and data
  const labels = chartData?.map((dataPoint) =>
    new Date(dataPoint.date).toLocaleDateString("en-US", { weekday: "short" })
  );
  const dataValues = chartData?.map((dataPoint) => dataPoint.value);

  const data = {
    labels,
    datasets: [
      {
        label: "Burnout Rate",
        data: dataValues,
        fill: false,
        borderColor: "rgba(127, 199, 217, 1)",
        tension: 0.4,
        pointBackgroundColor: "#36A2EB",
      },
    ],
  };

  const options = {
    scales: {
      x: { display: true },
      y: { display: true, max: 100, min: 0 },
    },
    plugins: {
      legend: { display: false, position: "bottom" },
      shadowPlugin: {}, // Include the custom plugin in the options
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: "250px" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default TotalBurnoutRate;
