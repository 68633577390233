import React, { useState } from "react";
import styles from "./Section.module.css";
import Team from "../Team";
import { useStructure } from "../../../contexts/StructureContext";
import { structureService } from "../../../services/structureService";
import { toast } from "react-toastify";

const Section = ({ section }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(section.title);
  const { fetchSections } = useStructure();

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleDelete = (id) => {
    structureService
      .deleteSection(id)
      .then(() => {
        fetchSections();
      })
      .catch((error) => {
        console.error("Failed to delete section:", error);
      });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async (id) => {
    try{
      const res = await structureService.editSection(id, newTitle);
      toast.info(res.message)
     } catch(error) {
       console.error("Failed to edit section:", error);
      } finally {
        fetchSections();
        setIsEditing(false);}
  };

  const handleChange = (e) => {
    setNewTitle(e.target.value);
  };

  return (
    <div className={`${styles.section}`}>
      <div onClick={handleToggleCollapse} className={styles[`section-header`]}>
        <span className={styles[`section-name`]}>
          {isCollapsed && (
            <span className={styles.plus}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 12H19"
                  stroke="#365486"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 5V19"
                  stroke="#365486"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          )}

          <span className={styles.name}>
            <div>Section</div>
            <div>
              {isEditing ? (
                <input
                  type="text"
                  value={newTitle}
                  onChange={handleChange}
                  onBlur={() => handleSave(section.id)}
                  autoFocus
                />
              ) : (
                <>
                  {section.title}
                  <span className={styles[`num`]}>
                    {section?.teams?.length}
                  </span>
                </>
              )}
            </div>
          </span>
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation(); // Prevent collapse toggle
            isEditing ? handleSave(section.id) : handleEdit();
          }}
          className={styles[`edit-icon`]}
        >
          {isEditing ? "✔️" : "✏️"}
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation(); // Prevent collapse toggle
            handleDelete(section.id);
          }}
          className={styles[`delete-icon`]}
        >
          🗑️
        </span>
      </div>
      <div className={`${styles.teams} ${isCollapsed ? styles.collapsed : ""}`}>
        {section?.teams?.map((team) => (
          <Team key={team.id} team={team} />
        ))}
      </div>
    </div>
  );
};

export default Section;
