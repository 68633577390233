import React, { useState } from "react";
import styles from "./CompleteInfoForm.module.css";
import InputField from "../../common/InputField";
import PhoneNumberInput from "../../common/PhoneNumberInput";
import ImagePicker from "../../common/ImagePicker";
import SubmitButton from "../../common/buttons/SubmitButton";
import { validateFormOnSubmit } from "../../../utils/validation";
import { put } from "../../../utils/api";
import { toast } from "react-toastify";
import { authService } from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
function CompleteInfoForm() {
  const [errors, setErrors] = useState({});
  const { initializeToken } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_number: "",
    company_name: "",
    job_title: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      return updatedData;
    });
  };

  const handleSubmit = async () => {
    try {
      // Validate fields on submit
      const formErrors = validateFormOnSubmit(formData);
      setErrors(formErrors);

      // If there are any errors, prevent form submission
      if (Object.keys(formErrors).length > 0) {
        toast.error("Please correct the errors in the form");
        return;
      }

      const response = await put("account/info/", formData);
      console.log("API Response:", response);

      await authService.updateUserInfo(formData);

      setFormData({
        first_name: "",
        last_name: "",
        company_number: "",
        company_name: "",
        job_title: "",
        phone: "",
      });

      toast.success("Form submitted successfully!");
      initializeToken();
      navigate("/dashboard");
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <ImagePicker />
        <div className={styles.inputGroupRow}>
          <div>
            <InputField
              type="text"
              placeholder="First name"
              name="first_name"
              value={formData?.["first_name"]}
              onChange={handleChange}
            />
            {errors.first_name && (
              <span className={styles.errorText}>{errors.first_name}</span>
            )}
          </div>
          <div>
            <InputField
              type="text"
              placeholder="Last name"
              name="last_name"
              value={formData?.["last_name"]}
              onChange={handleChange}
            />
            {errors.last_name && (
              <span className={styles.errorText}>{errors.last_name}</span>
            )}
          </div>
        </div>
        <div className={styles.inputGroupCol}>
          <PhoneNumberInput
            placeholder="Company number"
            name="company_number"
            type="tel"
            value={formData?.["company_number"]}
            onChange={handleChange}
          />
          {errors.company_number && (
            <span className={styles.errorText}>{errors.company_number}</span>
          )}
          <InputField
            type="text"
            placeholder="Company name"
            name="company_name"
            value={formData?.["company_name"]}
            onChange={handleChange}
          />
          {errors.company_name && (
            <span className={styles.errorText}>{errors.company_name}</span>
          )}
        </div>
        <div className={styles.inputGroupCol}>
          <InputField
            type="text"
            placeholder="Job title"
            name="job_title"
            value={formData?.["job_title"]}
            onChange={handleChange}
          />
          {errors.job_title && (
            <span className={styles.errorText}>{errors.job_title}</span>
          )}
          <PhoneNumberInput
            placeholder="Phone number"
            name="phone"
            type="tel"
            value={formData?.["phone_"]}
            onChange={handleChange}
          />
          {errors.phone_number && (
            <span className={styles.errorText}>{errors.phone}</span>
          )}
        </div>
        <SubmitButton action={handleSubmit} title={"Continue"} />
      </div>
    </div>
  );
}

export default CompleteInfoForm;
