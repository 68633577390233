import React from "react";
import styles from "./Dashboard.module.css";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { DashboardProvider } from "../../contexts/DashboardContext";
function Dashboard() {
  return (
    <DashboardProvider>
      <section className={styles.container}>
        <DashboardLayout />
      </section>
    </DashboardProvider>
  );
}

export default Dashboard;
