import React, { useState } from "react";
import styles from "./MentalHealthWidget.module.css";
import TotalMentalHealthRate from "./TotalMentalHealthRate";
import ProgressBar from "@ramonak/react-progress-bar";
import MentalHealthModal from "./MentalHealthModal";

function MentalHealthWidget({ data }) {
  const [toggled, setToggled] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Total Mental Health Rate</h2>
        <span
          className={styles.toggle}
          onClick={(e) => {
            e.preventDefault();
            setToggled((prev) => !prev);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
              stroke="#BDBDBD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className={styles.body}>
        <div className={styles.chart}>
          <TotalMentalHealthRate data={data} />
        </div>
        <div className={styles.detail}>
          <div className={styles.detailHeader}>
            <div className={styles.ProgressBarWrapper}>
              <div className={styles.actives}>
                <span className={styles.icon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.0003 17.5C15.0003 15.7319 14.2979 14.0362 13.0477 12.7859C11.7975 11.5357 10.1018 10.8333 8.33366 10.8333C6.56555 10.8333 4.86986 11.5357 3.61961 12.7859C2.36937 14.0362 1.66699 15.7319 1.66699 17.5"
                      stroke="#7D7D7D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.33366 10.8333C10.6348 10.8333 12.5003 8.96785 12.5003 6.66667C12.5003 4.36548 10.6348 2.5 8.33366 2.5C6.03247 2.5 4.16699 4.36548 4.16699 6.66667C4.16699 8.96785 6.03247 10.8333 8.33366 10.8333Z"
                      stroke="#7D7D7D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.3333 16.6667C18.3333 13.8583 16.6667 11.25 15 10C15.5478 9.58897 15.9859 9.04923 16.2755 8.42855C16.565 7.80787 16.6971 7.12539 16.66 6.4415C16.6229 5.75761 16.4178 5.0934 16.0629 4.50766C15.7079 3.92192 15.2141 3.43271 14.625 3.08333"
                      stroke="#7D7D7D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span className={styles.number}>{data?.active_users}</span>
                <span className={styles.number}>Active User</span>
              </div>
              <div className={styles.ProgressBar}>
                <ProgressBar
                  customLabel="Anxiety Signals"
                  height="20px"
                  labelSize="12px"
                  labelAlignment="outside"
                  borderRadius="8px"
                  baseBgColor="rgba(245, 245, 245, 1)"
                  completed="40"
                  // completed={data?.overal_report?.anxiety.value}
                  labelColor="rgba(97, 97, 97, 1)"
                  bgColor="rgba(127, 199, 217, 1)"
                  animateOnRender="true"
                />
                <ProgressBar
                  customLabel="Work-Life Balance Issues"
                  labelSize="12px"
                  animateOnRender="true"
                  height="20px"
                  labelAlignment="outside"
                  borderRadius="8px"
                  baseBgColor="rgba(245, 245, 245, 1)"
                  completed={data?.overal_report?.Stress.title}
                  labelColor="rgba(97, 97, 97, 1)"
                  bgColor="rgba(127, 199, 217, 1)"
                />
                <ProgressBar
                  customLabel="Emotional Expressions"
                  labelSize="12px"
                  labelAlignment="outside"
                  animateOnRender="true"
                  height="20px"
                  borderRadius="8px"
                  baseBgColor="rgba(245, 245, 245, 1)"
                  completed={data?.overal_report?.anxiety.value}
                  labelColor="rgba(97, 97, 97, 1)"
                  bgColor="rgba(127, 199, 217, 1)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggled && (
        <div
          className={styles.modal}
          onClick={(e) => {
            e.preventDefault();
            setToggled(false);
          }}
        >
          <MentalHealthModal data={data} />
        </div>
      )}
    </div>
  );
}

export default MentalHealthWidget;
