import React from 'react'
import Style from './NotFound.module.css';
import url from '../../assets/images/404.png';
function NotFound() {
    return (
        <div className={Style.container}>
            <img src={url} alt="404" />
        </div>
    )
}

export default NotFound