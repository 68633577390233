import React, { createContext, useContext, useState, useEffect } from 'react';
import { manageScoreService } from '../services/manageScoreService';

const ScoreContext = createContext();

export const ScoreProvider = ({ children }) => {
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);

 
  const fetchAllScores = async () => {
    setLoading(true);
    try {
      const data = await manageScoreService.fetchAllScores();
      if (data.message === "success") {
        console.log(data.data)
         setScores(data.data);
      } else {
          console.error('Failed to fetch scores:', data);
        }
     
    } catch (error) {
      console.error('Failed to fetch scores:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchAllScores();
  }, []);

  return (
    <ScoreContext.Provider value={{
      scores,
      loading,
      fetchAllScores,
    }}>
      {children}
    </ScoreContext.Provider>
  );
};

export const useScore = () => useContext(ScoreContext);
