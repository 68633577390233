import React, { useState } from "react";
import styles from "./Department.module.css";
import Section from "../Section";
import { structureService } from "../../../services/structureService";
import { useStructure } from "../../../contexts/StructureContext";
import { toast } from "react-toastify";

const Department = ({ department }) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(department?.name);
  const { fetchDepartments } = useStructure();

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleDelete = (id) => {
    structureService
      .deleteDepartment(id)
      .then(() => {
        fetchDepartments();
      })
      .catch((error) => {
        console.error("Failed to delete department:", error);
      });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async (id) => {
    try {
    const res = await structureService.editDepartment(id, newName);
    toast.info(res.message)
      } catch(error) {
        console.error("Failed to edit department:", error);
      } finally {
        fetchDepartments();
        setIsEditing(false);
      }
    
  };

  const handleChange = (e) => {
    setNewName(e.target.value);
  };

  return (
    <div className={styles.department}>
      <div
        className={styles[`department-header`]}
        onClick={handleToggleCollapse}
      >
        <span className={styles[`department-name`]}>
          {isCollapsed && (
            <span className={styles.plus}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 12H19"
                  stroke="#365486"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 5V19"
                  stroke="#365486"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          )}
          <span className={styles.name}>
            <div>Department</div>
            <div>
              {isEditing ? (
                <input
                  type="text"
                  value={newName}
                  onChange={handleChange}
                  autoFocus
                />
              ) : (
                <>
                  {department.name}
                  <span className={styles.num}>
                    {department?.sections?.length}
                  </span>
                </>
              )}
            </div>
          </span>
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            isEditing ? handleSave(department.id) : handleEdit();
          }}
          className={styles[`edit-icon`]}
        >
          {isEditing ? "✔️" : "✏️"}
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(department.id);
          }}
          className={styles[`delete-icon`]}
        >
          🗑️
        </span>
      </div>
      <div
        className={`${styles.sections} ${isCollapsed ? styles.collapsed : ""}`}
      >
        {department?.sections.map((section) => (
          <Section
            key={section.id}
            section={section}
            isSelected={selectedSection === section}
            onSectionClick={handleSectionClick}
          />
        ))}
      </div>
    </div>
  );
};

export default Department;
