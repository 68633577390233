import { put } from "../utils/api";

export const manageNotificationService = {
  sendToken: async (token) => {
    return await put(
      "account/device-tokens/",
      { device_tokens: [token] },
      true
    );
  },
};
